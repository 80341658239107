import React from 'react';
import { Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import http from 'src/services/httpService';
import Input from 'src/componentes/input';
import ColaboradorSimplificadoLookup from 'src/lookups/ColaboradorSimplificadoLookup';
import { capitalizeFirstLetter } from 'src/utils/string';
import StatusAcaoLookup from 'src/lookups/status-acao';
import ItemOrganizacaoLookup from 'src/lookups/item-organizacao';
import DatePicker from 'src/componentes/date-picker';
import PeriodicidadeLookup from 'src/lookups/periodicidade';
import Checkbox from 'src/componentes/checkbox';
import TemaEstrategicoLookup from 'src/lookups/tema-estrategico';
import ItemCentroCustoLookup from 'src/lookups/item-centro-custo';
import PacoteLookup from 'src/lookups/pacote';
import ItemLookup from 'src/lookups/item';
import FarolAcaoLookup from 'src/lookups/farol-acao';
import TipoItemLookup from 'src/lookups/tipo-item';
import TipoAcaoLookup from 'src/lookups/tipo-acao';
import DesdobramentoLookup from 'src/lookups/desdobramento';
import ClassificacaoLookup from 'src/lookups/classificacao-mo';
import NivelAcaoLookup from 'src/lookups/nivel-acao';
import MatrizRiscoLookup from 'src/lookups/matriz-risco';
import TagLookup from 'src/lookups/TagLookup';
import GrauRiscoLookup from 'src/lookups/grau-risco';
import AuditoriaRiscoLookup from 'src/lookups/auditoriaRisco';
import DesenharCampoCustomizado from '../item/campo-customizado/componentes/desenhar';
import PeriodoData from 'src/paginas/data/periodo-data.jsx';
import ControlSelfAssessmentLookup from 'src/lookups/controlSelfAssessment';
import StatusBaselineLookup from 'src/lookups/StatusBaselineLookup';
import PanelCollapse from 'src/componentes/panel-collapse';
class OrganizacaoSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configuracoes: null,
      exibirClassificacao: false,
      filtroDefault: {
        tipoFiltroData: this.props?.model?.getModel('tipoFiltroDataCriacaoAcao')
          ?.value && {
          id: this.props.model.getModel('tipoFiltroDataCriacaoAcao').value.id,
          descricao: this.props.model.getModel('tipoFiltroDataCriacaoAcao')
            .value.descricao,
        },
        dataInicio:
          this.props.model &&
          this.props.model.getModel('dataInicialCriacaoAcao').value
            ? this.props.model.getModel('dataInicialCriacaoAcao').value
            : null,
        dataTermino:
          this.props.model &&
          this.props.model.getModel('dataFinalCriacaoAcao').value
            ? this.props.model.getModel('dataFinalCriacaoAcao').value
            : null,
      },
    };
  }

  componentDidMount() {
    let { moduloAtual } = this.props;

    http.get(`/AcaoConfiguracao/ObterConfiguracoes`).then((response) => {
      this.setState({
        configuracoes: response.data,
      });
    });

    if (moduloAtual.id === 2) {
      http
        .post(`/CentroCusto/ObterConfiguracaoSistema`, {})
        .then((response) => {
          this.setState({
            exibirClassificacao: response.data.exibirClassificacao,
          });
        });
    }

    this.updateStatusField();

    this.props.model
      .getModel('tipoFiltroDataCriacaoAcao')
      .requestChange(this.state.filtroDefault.tipoFiltroData);
    this.props.model
      .getModel('dataInicialCriacaoAcao')
      .requestChange(this.state.filtroDefault.dataInicio);
    this.props.model
      .getModel('dataFinalCriacaoAcao')
      .requestChange(this.state.filtroDefault.dataTermino);
    this.getOptionsPeriodo();
  }

  getOptionsPeriodo() {
    http
      .post(`/TipoFiltroDataLookup/Search`, {
        pageSize: 999999,
      })
      .then((response) => {
        const list = response.data.list;
        this.setState({
          filtroDefault: {
            ...this.state.filtroDefault,
          },
          options: list,
        });
      });
  }

  // Novo filtro Multi - Sem impactar o antigo (outras telas, etc.)
  updateStatusField() {
    const { model } = this.props || {};

    const status = model.getModel('status').value;

    if (status) {
      const listaStatus = model.getModel('listaStatus').value;
      if (!listaStatus || listaStatus?.length === 0) {
        model.getModel('listaStatus').requestChange([{ ...status }]);
        model.getModel('status').requestChange(null);
      }
    }
  }

  handleChangeClassificacao(value) {
    let { model } = this.props;

    model.getModel('classificacao').requestChange(value);

    model.getModel('pacote').requestChange(null);
    model.getModel('pacoteId').requestChange(null);
    model.getModel('centroCusto').requestChange(null);
    model.getModel('centroCustoId').requestChange(null);

    this.setState({
      classificacaoId: value ? value.id : null,
    });
  }

  handleChangeFiltro(field, value) {
    const { model } = this.props;
    model.getModel(field).requestChange(value);
  }

  render() {
    let {
      model,
      resources,
      intl,
      moduloAtual,
      parametroUrl,
      configuracaoSistema,
    } = this.props;
    let { configuracoes, exibirClassificacao } = this.state;
    let classificacaoId = exibirClassificacao
      ? model.getModel('classificacao') &&
        model.getModel('classificacao').value != null
        ? model.getModel('classificacao').value.id
        : -1
      : null;

    return (
      <div>
        <PanelCollapse
          md={12}
          open={false}
          header={capitalizeFirstLetter(resources.item)}
          headerStyle="text-primary"
          className="mb-3"
          style={{ minWidth: '1000px', width: '100%' }}
        >
          <div>
            <Row>
              <Col md={12}>
                <ItemLookup
                  clearable={true}
                  multi={true}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage({ id: 'item' })
                    ),
                    value: model.getModel('itens').value,
                    requestChange: model.getModel('itens').requestChange,
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <Checkbox
                  className="d-inline-block mr-2"
                  model={model.getModel('incluirItensFormula')}
                />
                <Checkbox
                  className="d-inline-block mr-2"
                  model={model.getModel('incluirDesdobramento')}
                />
                <Checkbox
                  className="d-inline-block mr-2"
                  model={model.getModel('incluirImpactosIndiretos')}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={6}>
                <PeriodicidadeLookup
                  clearable
                  model={model.getModel('periodicidade')}
                />
              </Col>

              <Col md={6}>
                <TemaEstrategicoLookup
                  multi={false}
                  clearable
                  model={{
                    label: capitalizeFirstLetter(resources.temaestrategico),
                    value: model.getModel('tema').value,
                    requestChange: model.getModel('tema').requestChange,
                  }}
                />
              </Col>
            </Row>

            <Row>
              {configuracaoSistema.utilizarFuncionalidadeDesdobramento && (
                <Col md={6}>
                  <DesdobramentoLookup
                    clearable={true}
                    model={{
                      label: intl.formatMessage({ id: 'desdobramento' }),
                      value: model.getModel('desdobramento').value,
                      requestChange:
                        model.getModel('desdobramento').requestChange,
                    }}
                  />

                  <Checkbox
                    model={model.getModel('desdobramentoSubordinados')}
                  />
                </Col>
              )}

              <Col md={6}>
                <ItemOrganizacaoLookup
                  multi={true}
                  model={{
                    label: capitalizeFirstLetter(resources.areas),
                    value: model.getModel('areas').value,
                    requestChange: model.getModel('areas').requestChange,
                  }}
                  data={{ tiposIdSelecionaveis: [1, 2, 3] }}
                />
                <Checkbox model={model.getModel('areaSubordinada')} />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={6}>
                <AuditoriaRiscoLookup
                  clearable={true}
                  multi={true}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage({ id: 'label.auditorias' })
                    ),
                    value: model.getModel('auditorias').value,
                    requestChange: model.getModel('auditorias').requestChange,
                  }}
                />
              </Col>
              <Col md={6}>
                <ControlSelfAssessmentLookup
                  clearable={true}
                  multi={true}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage({ id: 'labelControlSelfAssessment' })
                    ),
                    value: model.getModel('controlSelfAssessments').value,
                    requestChange: model.getModel('controlSelfAssessments')
                      .requestChange,
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <ColaboradorSimplificadoLookup
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'responsavelItem' },
                        {
                          responsavel: resources.responsavel,
                          item: resources.item,
                        }
                      )
                    ),
                    value: model.getModel('responsavelItem').value,
                    requestChange:
                      model.getModel('responsavelItem').requestChange,
                  }}
                />
              </Col>
            </Row>
          </div>
        </PanelCollapse>

        <PanelCollapse
          md={12}
          open={false}
          header={capitalizeFirstLetter(resources.acao)}
          headerStyle="text-primary"
          className="mb-3"
          style={{ minWidth: '1000px', width: '100%' }}
        >
          <div style={{ width: '100%' }}>
            <Row>
              <Col md={12}>
                <Input model={model.getModel('descricao')} />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Input model={model.getModel('codigo')} />
              </Col>
              <Col md={4}>
                <FarolAcaoLookup
                  multi
                  clearable
                  model={{
                    label: capitalizeFirstLetter(resources.farol),
                    value: model.getModel('farol').value,
                    requestChange: model.getModel('farol').requestChange,
                  }}
                  parametroUrl={parametroUrl && parametroUrl.farol}
                />
              </Col>

              <Col md={4}>
                <StatusAcaoLookup
                  multi
                  clearable
                  model={{
                    label: intl.formatMessage({ id: 'status' }),
                    value: model.getModel('listaStatus').value,
                    requestChange: model.getModel('listaStatus').requestChange,
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <DatePicker
                  autoFormatDate={true}
                  model={model.getModel('dataInicioPrevista')}
                />
              </Col>
              <Col md={6}>
                <DatePicker
                  autoFormatDate={true}
                  model={model.getModel('dataTerminoPrevista')}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <DatePicker
                  autoFormatDate={true}
                  model={model.getModel('dataInicioRealizada')}
                />
              </Col>
              <Col md={6}>
                <DatePicker
                  autoFormatDate={true}
                  model={model.getModel('dataTerminoRealizada')}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <ItemOrganizacaoLookup
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'label.colaboradoresArea' },
                        {
                          colaboradores: resources.colaboradores,
                          area: resources.area,
                        }
                      )
                    ),
                    value: model.getModel('areaColaboradores').value,
                    requestChange:
                      model.getModel('areaColaboradores').requestChange,
                  }}
                  data={{ tiposIdSelecionaveis: [1, 2, 3] }}
                />
              </Col>

              {configuracoes &&
                configuracoes.model &&
                configuracoes.model.habilitarEquipe && (
                  <Col md={6}>
                    <ColaboradorSimplificadoLookup
                      multi
                      model={{
                        label: intl.formatMessage({ id: 'equipe' }),
                        value: model.getModel('equipe').value,
                        requestChange: model.getModel('equipe').requestChange,
                      }}
                    />
                  </Col>
                )}
            </Row>

            <Row>
              <Col md={6}>
                <ColaboradorSimplificadoLookup
                  model={{
                    label: intl.formatMessage(
                      { id: 'criadorAcao' },
                      { acao: resources.acao }
                    ),
                    value: model.getModel('criadorAcao').value,
                    requestChange: model.getModel('criadorAcao').requestChange,
                  }}
                />
              </Col>

              {configuracoes &&
                configuracoes.model &&
                configuracoes.model.tipoAcao && (
                  <Col md={6}>
                    <TipoAcaoLookup
                      clearable
                      model={{
                        label: intl.formatMessage(
                          { id: 'tipoAcao' },
                          { acao: resources.acao }
                        ),
                        value: model.getModel('tipoAcao').value,
                        requestChange: model.getModel('tipoAcao').requestChange,
                      }}
                    />
                  </Col>
                )}
            </Row>

            <Row>
              <Col md={6}>
                <NivelAcaoLookup
                  clearable
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage(
                        { id: 'nivelAcao' },
                        { acao: resources.acao }
                      )
                    ),
                    value: model.getModel('nivelAcao').value,
                    requestChange: model.getModel('nivelAcao').requestChange,
                  }}
                />
              </Col>

              {configuracoes &&
                configuracoes.model &&
                configuracoes.model.podeVisualizarTag && (
                  <Col md={6}>
                    <TagLookup
                      multi
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage(
                            { id: 'tags' },
                            { tags: resources.tags }
                          )
                        ),
                        value: model.getModel('tags').value,
                        requestChange: model.getModel('tags').requestChange,
                      }}
                      data={{ funcionalidadesIdSelecionaveis: [6] }}
                      podeEditar={false}
                    />
                  </Col>
                )}
            </Row>

            <Row>
              <Col md={12}>
                <PeriodoData
                  adjustSize={true}
                  exibirDatas={true}
                  clearable
                  data={{ idsIgnorados: [7, 8, 9, 10] }}
                  tipoFiltroData={
                    model.getModel('tipoFiltroDataCriacaoAcao').value
                  }
                  dataInicio={model.getModel('dataInicialCriacaoAcao').value}
                  dataTermino={model.getModel('dataFinalCriacaoAcao').value}
                  tipoFiltroDataField={'tipoFiltroDataCriacaoAcao'}
                  dataInicioField={'dataInicialCriacaoAcao'}
                  dataTerminoField={'dataFinalCriacaoAcao'}
                  onChange={this.handleChangeFiltro.bind(this)}
                  customLabelTipoPeriodo={capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'tipoFiltroDataCriacaoAcao' },
                      { acao: resources.acao }
                    )
                  )}
                  customLabelDataInicio={capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'dataInicialCriacaoAcao' },
                      { acao: resources.acao }
                    )
                  )}
                  customLabelDataTermino={capitalizeFirstLetter(
                    intl.formatMessage(
                      { id: 'dataFinalCriacaoAcao' },
                      { acao: resources.acao }
                    )
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                {configuracoes &&
                  configuracoes.model &&
                  configuracoes.model.tipoOrigem && (
                    <TipoItemLookup
                      clearable
                      model={{
                        label: intl.formatMessage({ id: 'tipoOrigem' }),
                        value: model.getModel('tipoOrigem').value,
                        requestChange:
                          model.getModel('tipoOrigem').requestChange,
                      }}
                      data={{
                        tiposIdSelecionaveis: [1, 2, 3, 4, 5, 6, 7, 16, 19, 21],
                      }}
                    />
                  )}
              </Col>
              <Col md={6}>
                {configuracoes &&
                  configuracoes.model &&
                  configuracoes.model.exibirGrauDescricaoAcaoVinculadaRisco && (
                    <GrauRiscoLookup
                      clearable
                      multi
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage(
                            { id: 'grauRisco' },
                            { risco: resources.risco }
                          )
                        ),
                        value: model.getModel('grausRisco').value,
                        requestChange:
                          model.getModel('grausRisco').requestChange,
                      }}
                    />
                  )}
              </Col>
            </Row>

            <Row>
              {configuracoes &&
                configuracoes.model &&
                configuracoes.model.vincularAcaoNaMatriz && (
                  <Col md={6}>
                    <MatrizRiscoLookup
                      clearable
                      model={{
                        label: capitalizeFirstLetter(
                          intl.formatMessage(
                            { id: 'matrizRisco' },
                            { risco: this.props.resources.risco }
                          )
                        ),
                        value: model.getModel('matrizRisco').value,
                        requestChange:
                          model.getModel('matrizRisco').requestChange,
                      }}
                    />
                  </Col>
                )}

              {configuracoes &&
                configuracoes.model &&
                configuracoes.model.habilitarBaseline && (
                  <>
                    <Col md={6}>
                      <DatePicker
                        autoFormatDate={true}
                        model={{
                          label: intl.formatMessage({ id: 'baselineInicial' }),
                          value: model.getModel('baselineInicial').value,
                          requestChange:
                            model.getModel('baselineInicial').requestChange,
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <DatePicker
                        autoFormatDate={true}
                        model={{
                          label: intl.formatMessage({ id: 'baselineTermino' }),
                          value: model.getModel('baselineTermino').value,
                          requestChange:
                            model.getModel('baselineTermino').requestChange,
                        }}
                      />
                    </Col>
                    {configuracoes.model.exibirStatusBaseLineTermino && (
                      <Col md={6}>
                        <StatusBaselineLookup
                          clearable
                          model={{
                            label: intl.formatMessage({
                              id: 'labelStatusBaseLine',
                            }),
                            value: model.getModel('statusBaseline').value,
                            requestChange:
                              model.getModel('statusBaseline').requestChange,
                          }}
                        />
                      </Col>
                    )}
                  </>
                )}
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <Checkbox
                  model={{
                    label: intl.formatMessage(
                      { id: 'label.apenasAcoesModuloAtual' },
                      { acoes: resources.acoes }
                    ),
                    value: model.getModel('apenasAcoesModuloAtual').value,
                    requestChange: model.getModel('apenasAcoesModuloAtual')
                      .requestChange,
                  }}
                />
              </Col>
              <Col md={4}>
                <Checkbox
                  model={{
                    label: intl.formatMessage(
                      { id: 'label.apenasAcoesOrigem' },
                      { acoes: resources.acoes }
                    ),
                    value: model.getModel('apenasAcoesOrigem').value,
                    requestChange:
                      model.getModel('apenasAcoesOrigem').requestChange,
                  }}
                />
              </Col>
              <Col md={4}>
                <Checkbox
                  model={{
                    label: intl.formatMessage(
                      { id: 'acaoCadastradaAprovacao' },
                      { acoes: resources.acoes, acao: resources.acao }
                    ),
                    value: model.getModel('acaoCadastradaAprovacao').value,
                    requestChange: model.getModel('acaoCadastradaAprovacao')
                      .requestChange,
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <ColaboradorSimplificadoLookup
                  multi={true}
                  model={{
                    label: capitalizeFirstLetter(resources.responsaveis),
                    value: model.getModel('responsaveis').value,
                    requestChange: model.getModel('responsaveis').requestChange,
                  }}
                />
                {configuracoes?.model?.habilitarEquipe && (
                  <Checkbox
                    className="mb-3"
                    model={{
                      label: intl.formatMessage(
                        { id: 'labelConsiderarResponsavelNaEquipe' },
                        { acoes: resources.acoes }
                      ),
                      value: model.getModel('exibirAcoesComoMembroEquipe')
                        .value,
                      requestChange: model.getModel(
                        'exibirAcoesComoMembroEquipe'
                      ).requestChange,
                    }}
                  />
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                {configuracoes &&
                  configuracoes.model &&
                  configuracoes.model.utilizarPorque && (
                    <Input
                      rows={1}
                      type="textarea"
                      model={{
                        label: capitalizeFirstLetter(resources.porque),
                        value: model.getModel('porque').value,
                        requestChange: model.getModel('porque').requestChange,
                      }}
                    />
                  )}
              </Col>
            </Row>

            {moduloAtual.id === 2 && (
              <Row>
                {exibirClassificacao && (
                  <Col md={6}>
                    <ClassificacaoLookup
                      clearable
                      model={{
                        label: intl.formatMessage({
                          id: 'label.classificacao',
                        }),
                        value: model.getModel('classificacao').value,
                        requestChange:
                          this.handleChangeClassificacao.bind(this),
                      }}
                    />
                  </Col>
                )}
                <Col md={6}>
                  <ItemCentroCustoLookup
                    model={{
                      label: intl.formatMessage(
                        { id: 'centroDeCusto' },
                        { centrocusto: resources.centrocusto }
                      ),
                      value: model.getModel('centroCusto').value,
                      requestChange:
                        model.getModel('centroCusto').requestChange,
                    }}
                    data={{ classificacaoId: classificacaoId }}
                  />

                  <Checkbox
                    className="mt-3"
                    model={{
                      value: model.getModel('centrosSubordinados').value,
                      label: intl.formatMessage(
                        { id: 'label.centrosSubordinados' },
                        { centroscusto: resources.centroscusto }
                      ),
                      requestChange: model.getModel('centrosSubordinados')
                        .requestChange,
                    }}
                  />
                </Col>
              </Row>
            )}

            {moduloAtual.id === 2 && (
              <Row>
                <Col md={12}>
                  <PacoteLookup
                    model={{
                      label: intl.formatMessage({ id: 'pacoteConta' }),
                      value: model.getModel('pacote').value,
                      requestChange: model.getModel('pacote').requestChange,
                    }}
                    data={{ classificacaoId: classificacaoId }}
                  />
                </Col>
                <Checkbox
                  model={model.getModel('pacotesSubordinados')}
                  className="mt-3"
                />
              </Row>
            )}
          </div>
        </PanelCollapse>

        <PanelCollapse
          md={12}
          open={false}
          header={capitalizeFirstLetter(
            intl.formatMessage({ id: 'label.camposCustomizados' })
          )}
          headerStyle="text-primary"
          className="mb-3"
          style={{ minWidth: '1000px', width: '100%' }}
        >
          {model.getModel('camposCustomizados') &&
            model.getModel('camposCustomizados').value && (
              <Row>
                <Col md={12}>
                  <DesenharCampoCustomizado
                    model={model.getModel('camposCustomizados')}
                  />
                </Col>
              </Row>
            )}
        </PanelCollapse>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    moduloAtual: state.user.moduloAtual,
    configuracaoSistema: state.user.configuracao,
  };
}

export default injectIntl(connect(mapStateToProps)(OrganizacaoSearch));
