import React from 'react';
import {
  Popover,
  PopoverBody,
  Button as ButtonReactstrap,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  Badge,
} from 'reactstrap';
import { autobind } from 'core-decorators';
import http from 'src/services/httpService';
import history from '../../history';
import moment from 'moment';
import List from '../../componentes/list';
import ListColumn from '../../componentes/list/list-column';
import AcaoEdit from './edit';
import ContentManager from '../../componentes/content-manager';
import SvgIcon from '../../componentes/svg-icon';
import Search from './search';
import SearchAtualizarAcao from './search-atualizar-acao';
import SearchSchema from './search-schema';
import SearchModal from './search-modal';
import SearchSchemaModal from './search-schema-modal';
import errorHandler from '../../utils/error-handler';
import Confirm from '../../componentes/message-box/confirm';
import Legenda from '../../componentes/legenda/legenda-acao';
import LoadingContainer from '../../componentes/loading-container';
import { FormattedMessage, injectIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../utils/string';
import { connect } from 'react-redux';
import ValidacaoRestricao from '../item/validacao-restricao';
import qs from 'query-string';
import { Form } from '../../utils/form';
import SearchWidget from './search-widget';
import SearchWidgetSchema from './search-widget-schema';
import css from '../../utils/css';
import MdiIcon from '../../componentes/mdi-icon';
import {
  allColumnsCard,
  fetchStatusDisponiveis,
  fetchCard,
  fetchStatus,
} from './kanban/components/functions';
import InputNumber from 'src/componentes/input-number';
import Button from 'src/componentes/button';
import DatePicker from 'src/componentes/date-picker';

const classes = css`
  .descricao {
    text-align: left;
    background-color: #fff;
  }

  .descricaoNegrito {
    color: #0000ff;
    text-align: left;
    background-color: #fff;
    font-weight: bold;
  }
`;

@autobind
class AcaoList extends React.Component {
  static defaultProps = {
    showToolbar: true,
    usarFiltroSalvo: true,
    lang: 'en',
    kanban: true,
    atualizarAcoes: false,
  };

  constructor(props) {
    super(props);

    const objLocationSearch = this.props.location
      ? qs.parse(this.props.location.search)
      : {};

    this.state = {
      acaoHintId: 0,
      isLoadingHintAcao: false,
      arvoreHintAcao: null,
      parameters: this.props.params,
      saved: false,
      dropdownOpen: false,
      showNew: false,
      filtroAtualizado: false,
      dataInicioPrevista: objLocationSearch.dataInicioPrevista
        ? new Date(objLocationSearch.dataInicioPrevista)
        : null,
      dataTerminoPrevista: objLocationSearch.dataTerminoPrevista
        ? new Date(objLocationSearch.dataTerminoPrevista)
        : null,
      dataInicioRealizada: objLocationSearch.dataInicioRealizada
        ? new Date(objLocationSearch.dataInicioRealizada)
        : null,
      dataTerminoRealizada: objLocationSearch.dataTerminoRealizada
        ? new Date(objLocationSearch.dataTerminoRealizada)
        : null,
      descricao: objLocationSearch.descricao
        ? objLocationSearch.descricao
        : null,
      codigo: objLocationSearch.codigo ? objLocationSearch.codigo : null,
      idFarol: objLocationSearch.farolId ? objLocationSearch.farolId : null,
      farolDescricao: objLocationSearch.farolDescricao
        ? objLocationSearch.farolDescricao
        : null,
      farolIcone: objLocationSearch.farolIcone
        ? objLocationSearch.farolIcone
        : null,
      farolCor: objLocationSearch.farolCor ? objLocationSearch.farolCor : null,
      organizacaoId: objLocationSearch.organizacaoId
        ? objLocationSearch.organizacaoId
        : null,
      organizacaoNome: objLocationSearch.organizacaoNome
        ? objLocationSearch.organizacaoNome
        : null,
      subordinadas: objLocationSearch.subordinadas
        ? objLocationSearch.subordinadas
        : null,
      itemId: objLocationSearch.itemId ? objLocationSearch.itemId : null,
      itemNome: objLocationSearch.itemNome ? objLocationSearch.itemNome : null,
      acaoId: objLocationSearch.acaoId
        ? objLocationSearch.acaoId
        : objLocationSearch.acaoid
        ? objLocationSearch.acaoid
        : null,
      statusId: objLocationSearch.statusId ? objLocationSearch.statusId : null,
      statusNome: objLocationSearch.statusNome
        ? objLocationSearch.statusNome
        : null,
      organizacaoColaboradoresId:
        location && objLocationSearch.organizacaoColaboradoresId
          ? objLocationSearch.organizacaoColaboradoresId
          : null,
      organizacaoColaboradoresNome:
        location && objLocationSearch.organizacaoColaboradoresNome
          ? objLocationSearch.organizacaoColaboradoresNome
          : null,
      responsavelId: objLocationSearch.responsavelId
        ? objLocationSearch.responsavelId
        : null,
      responsavelNome: objLocationSearch.responsavelNome
        ? objLocationSearch.responsavelNome
        : null,
      usarFiltroSalvo: objLocationSearch.usarFiltroSalvo
        ? objLocationSearch.usarFiltroSalvo === '0'
          ? false
          : true
        : true,
      acaoConfiguracao: null,
      apenasAcoesModuloAtual:
        location && objLocationSearch.apenasAcoesModuloAtual
          ? objLocationSearch.apenasAcoesModuloAtual === 'true'
            ? true
            : false
          : null,
      exibirAcoesComoMembroEquipe:
        location && objLocationSearch.exibirAcoesComoMembroEquipe
          ? objLocationSearch.exibirAcoesComoMembroEquipe === 'true'
            ? true
            : false
          : null,
      filtrarAcoesEspelho: objLocationSearch.filtrarAcoesEspelho
        ? objLocationSearch.filtrarAcoesEspelho === '1'
          ? true
          : false
        : false,
      centroCustoId: objLocationSearch.centroCustoId
        ? objLocationSearch.centroCustoId
        : null,
      centroCustoNome: objLocationSearch.centroCustoNome
        ? objLocationSearch.centroCustoNome
        : null,
      pacoteId: objLocationSearch.pacoteId ? objLocationSearch.pacoteId : null,
      pacoteNome: objLocationSearch.pacoteNome
        ? objLocationSearch.pacoteNome
        : null,
      centrosSubordinados: objLocationSearch.centrosSubordinados
        ? objLocationSearch.centrosSubordinados === 'true'
          ? true
          : false
        : false,
      pacotesSubordinados: objLocationSearch.pacotesSubordinados
        ? objLocationSearch.pacotesSubordinados === 'true'
          ? true
          : false
        : false,
      tipoOrigemId: objLocationSearch.tipoOrigemId
        ? objLocationSearch.tipoOrigemId
        : null,
      parametroUrl: null,
      somenteAcoesFilhas: objLocationSearch.somenteAcoesFilhas
        ? objLocationSearch.somenteAcoesFilhas
        : false,
      nivelAcao: objLocationSearch.nivelAcao
        ? { id: objLocationSearch.nivelAcao }
        : null,
      allColumnsCard: [],
      allColumnsKanban: [],
      columnsCard: null,
      columnsKanban: null,
      usandoKanban: false,
      parametroSearchWidget: { farol: null },
      tipoAcaoId: objLocationSearch.tipoAcaoId
        ? objLocationSearch.tipoAcaoId
        : null,
      desdobramentoId: objLocationSearch.desdobramentoId
        ? objLocationSearch.desdobramentoId
        : null,
      desdobramentoDescricao:
        location && objLocationSearch.desdobramentoDescricao
          ? objLocationSearch.desdobramentoDescricao
          : null,
      desdobramentoSubordinados:
        location && objLocationSearch.desdobramentoSubordinados
          ? objLocationSearch.desdobramentoSubordinados
          : false,
      incluirDesdobramento: objLocationSearch.incluirDesdobramento
        ? objLocationSearch.incluirDesdobramento
        : false,
      incluirImpactosIndiretos:
        location && objLocationSearch.incluirImpactosIndiretos
          ? objLocationSearch.incluirImpactosIndiretos
          : false,
      incluirItensFormula: objLocationSearch.incluirItensFormula
        ? objLocationSearch.incluirItensFormula
        : false,
      subordinadasOrganizacaoColaboradores:
        location && objLocationSearch.subordinadasOrganizacaoColaboradores
          ? objLocationSearch.subordinadasOrganizacaoColaboradores
          : false,
      porque: null,
      updatedItems: [],
      porque: null,
      apenasAcoesOrigem: objLocationSearch.apenasAcoesOrigem
        ? objLocationSearch.apenasAcoesOrigem === 'true'
          ? true
          : false
        : false,
      widgetDashboardIndividual:
        location && objLocationSearch.widgetDashboardIndividual
          ? objLocationSearch.widgetDashboardIndividual
          : false,
      filtrarAcoesAreaColaborador:
        location && objLocationSearch.filtrarAcoesAreaColaborador
          ? objLocationSearch.filtrarAcoesAreaColaborador
          : false,
      acoes: objLocationSearch.acoesIds
        ? JSON.parse(objLocationSearch.acoesIds)?.map((x) => {
            return { id: x };
          })
        : null,
      baselineInicial: objLocationSearch.baselineInicial
        ? new Date(objLocationSearch.baselineInicial)
        : null,
      baselineTermino: objLocationSearch.baselineTermino
        ? new Date(objLocationSearch.baselineTermino)
        : null,
      matrizRiscoId: objLocationSearch.matrizRiscoId
        ? objLocationSearch.matrizRiscoId
        : null,
      matrizRiscoDescricao: objLocationSearch.matrizRiscoDescricao
        ? objLocationSearch.matrizRiscoDescricao
        : null,
      grauHintId: 0,
      grauInerenteHintId: 0,
      grausRiscoId: objLocationSearch.grausRiscoId
        ? objLocationSearch.grausRiscoId.split(',')
        : null,
      grausRiscoNome: objLocationSearch.grausRiscoNome
        ? objLocationSearch.grausRiscoNome.split(',')
        : null,
      auditoriasId: objLocationSearch.auditoriasId
        ? objLocationSearch.auditoriasId.split(',')
        : null,
      auditoriasNome: objLocationSearch.auditoriasNome
        ? objLocationSearch.auditoriasNome.split(',')
        : null,
        auditoriaId: objLocationSearch.auditoriaId
          ? objLocationSearch.auditoriaId
          : null,
        auditoriaNome: objLocationSearch.auditoriaNome
          ? objLocationSearch.auditoriaNome
          : null,
      camposCustomizados: objLocationSearch.camposCustomizados
        ? JSON.parse(decodeURIComponent(objLocationSearch.camposCustomizados))
        : null,
      tagsId: objLocationSearch.tagsId
        ? objLocationSearch.tagsId.split(',')
        : null,
      tagsDescricao: objLocationSearch.tagsDescricao
        ? objLocationSearch.tagsDescricao.split(',')
        : null,
      csasId: objLocationSearch.csasId
        ? objLocationSearch.csasId.split(',')
        : null,
      csasNome: objLocationSearch.csasNome
        ? objLocationSearch.csasNome.split(',')
        : null,
      idColaboradorEquipe: objLocationSearch.idColaboradorEquipe
        ? objLocationSearch.idColaboradorEquipe
        : null,
      nomeColaboradorEquipe: objLocationSearch.nomeColaboradorEquipe
        ? objLocationSearch.nomeColaboradorEquipe
        : null,
    };

    this.form = new Form({ component: this, schema: SearchSchema });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      this.props.apenasAcoesModuloAtual &&
      newProps.parameters.apenasAcoesModuloAtual
    ) {
      if (
        newProps.parameters.apenasAcoesModuloAtual !==
        this.props.apenasAcoesModuloAtual
      ) {
        this.setState(
          {
            apenasAcoesModuloAtual: newProps.parameters.apenasAcoesModuloAtual,
          },
          this.refresh
        );
      }
    }

    if (this.props.apenasAcoesOrigem && newProps.parameters.apenasAcoesOrigem) {
      if (
        newProps.parameters.apenasAcoesOrigem !== this.props.apenasAcoesOrigem
      ) {
        this.setState(
          { apenasAcoesOrigem: newProps.parameters.apenasAcoesOrigem },
          this.refresh
        );
      }
    }
  }

  componentDidMount() {
    if (this.props.params) {
      if (this.props.params.tipoItem && this.props.params.tipoItem === '3') {
        http
          .post(`/ItemConfiguracaoObjetivo/ObterConfiguracoes`, {
            parameters: this.props.parameters,
          })
          .then((response) => {
            if (response.data !== null) {
              this.setState({
                showNew: response.data.permitirCadastrarAcoes,
              });
            }
          })
          .catch((error) => {
            errorHandler(error);
          });
      }
    }
    if (!this.props.showModal) {
      this.atualizarParametros();
    }
    this.obterConfiguracoes();
  }

  async getAllInfos(configurations) {
    let allColumnsCardLocal = allColumnsCard(
      this.props.intl,
      this.props.resources,
      configurations
    );
    let columnsCard = await fetchCard(allColumnsCardLocal, this.props.viewType);
    let allColumnsKanban = await fetchStatusDisponiveis(this.props.viewType);
    let columnsKanban = await fetchStatus(
      allColumnsKanban,
      this.props.viewType
    );

    this.setState({
      acaoConfiguracao: configurations,
      allColumnsCard: allColumnsCardLocal,
      columnsCard: columnsCard,
      allColumnsKanban: allColumnsKanban,
      columnsKanban: columnsKanban,
    });
  }

  async obterConfiguracoes() {
    if (this.props.configurations) {
      await this.getAllInfos(this.props.configurations);
      return;
    }

    http
      .post(`/AcaoConfiguracao/ObterConfiguracoes`, {})
      .then(async (response) => {
        await this.getAllInfos(response.data.model);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleDesassociarClick(item) {
    http
      .post(`/Acao/DesassociarAcao`, {
        acao: item,
      })
      .then(() => {
        this.setState({
          saved: Math.random(),
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleRemoveAcoes() {
    ContentManager.addContent(
      <Confirm
        message={
          <FormattedMessage id="label.desejaMesmoExcluirTodosOsRegistros" />
        }
        handleConfirm={this.removeTodasAcoes}
      />
    );
  }

  removeTodasAcoes() {
    http
      .post(`/Acao/ExcluirTodasAcoes`, {
        itemId: this.props.params.itemId,
      })
      .then(() => {
        this.setState({
          saved: Math.random(),
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  handleOpenGantt() {
    history.push({
      pathname: '/gerenciar/gantt',
      search: `?itemId=${this.props.params.itemId}`,
    });
  }

  handleMouseOut() {
    this.setState({ acaoHintId: 0 });
  }

  handleMouseOutGrauRisco(inerente) {
    if (inerente) this.setState({ grauInerenteHintId: 0 });
    else this.setState({ grauHintId: 0 });
  }

  handleHintAcao(acoes, acaoId, acao) {
    if (acao && acao.id <= 0) {
      return (
        <tr key={acao.id}>
          <td
            className={
              acao.id == acaoId ? classes.descricaoNegrito : classes.descricao
            }
            style={{ background: '#ffffff' }}
          >
            <span>
              <div
                style={{
                  verticalAlign: 'middle',
                  paddingLeft: acao.level * 20,
                }}
              >
                {acao.codigo != null
                  ? acao.codigo + '-' + acao.descricao
                  : acao.descricao}
              </div>
            </span>
          </td>
        </tr>
      );
    }

    return acoes.data.itens.map((acao, index) => (
      <tr key={index * 2}>
        <td
          className={
            acao.id == acaoId ? classes.descricaoNegrito : classes.descricao
          }
          style={{ background: '#ffffff' }}
        >
          <span>
            <div
              style={{
                verticalAlign: 'middle',
                paddingLeft: acao.level * 20,
              }}
            >
              {acao.codigo != null
                ? acao.codigo + '-' + acao.descricao
                : acao.descricao}
            </div>
          </span>
        </td>
      </tr>
    ));
  }

  async handleMouseOver(acao) {
    this.setState({
      isLoadingHintAcao: true,
      acaoHintId: acao.id,
    });

    try {
      var retorno = await http.post(`/Acao/ObterHintAcoes`, {
        itemNome: acao.nomeItem,
        acaoId: acao.id,
        acaoDescricao: acao.descricao,
      });

      this.setState({ arvoreHintAcao: retorno, isLoadingHintAcao: false });
    } catch (error) {
      errorHandler(error);
      this.setState({
        isLoadingHintAcao: false,
      });
    }
  }

  handleMouseOverGrauRisco(item, inerente) {
    if (inerente)
      this.setState({
        grauInerenteHintId: item.id,
      });
    else
      this.setState({
        grauHintId: item.id,
      });
  }

  renderPopoverHintAcao(item) {
    return (
      <Popover
        placement="auto"
        isOpen={this.state.acaoHintId == item.id}
        target={'coldescricao' + item.id}
      >
        <PopoverBody style={{ width: 600 }}>
          <LoadingContainer isLoading={this.state.isLoadingHintAcao}>
            {this.state.arvoreHintAcao &&
              this.handleHintAcao(this.state.arvoreHintAcao, item.id, item)}
          </LoadingContainer>
        </PopoverBody>
      </Popover>
    );
  }

  handlePlanoAcaoClick(item) {
    let url = `?itemId=`;

    if (item.item.desdobramento != null) {
      url += `${item.item.id}&desdobramentoId=${item.item.desdobramento.id}`;
    } else {
      url += `${item.item.id}`;
    }

    history.push({
      pathname: '/gerenciar/gantt',
      search: url,
    });
  }

  renderDescricao(item, callback) {
    if (!item.descricao) {
      return;
    }
    return (
      <div
        onMouseEnter={this.handleMouseOver.bind(this, item)}
        onMouseLeave={this.handleMouseOut}
        id={'acaoid' + item.id}
      >
        {this.props.isConsultaExterna ? (
          <span>{item.descricao}</span>
        ) : (
          <a
            tabindex="-1"
            onClick={this.handleDescricaoClick.bind(this, item, callback)}
            style={{ cursor: 'pointer' }}
          >
            {item.descricao}
          </a>
        )}
      </div>
    );
  }

  handleDescricaoClick(item) {
    ContentManager.addContent(
      <AcaoEdit
        defaultActiveKey={0}
        showExport
        handleClose={this.handleCloseEdit}
        item={item}
        parameters={{ exibirLookups: true, ...this.state.parameters }}
        onDeleting={this.handleDeleteClick}
        {...this.props}
      />
    );
  }

  handleCloseEdit(saved) {
    if (saved) {
      this.props.refreshCount && this.props.refreshCount(saved);
      this.setState({ saved: Math.random() });
    }
  }

  renderResponsavel(item) {
    return <span>{item.responsavel ? item.responsavel.nome : ''}</span>;
  }

  renderStatus(item) {
    return <span>{item.status ? item.status.descricao : ''}</span>;
  }

  renderTipoOrigem(item, intl) {
    let tipoOrigem = null;

    if (item.tipoOrigem === 'reuniao')
      tipoOrigem = <FormattedMessage id="reuniao" />;
    else if (item.tipoOrigem === 'auditoria')
      tipoOrigem = <FormattedMessage id="titulo.auditoriaRisco" />;
    else if (item.tipoOrigem === 'auditoriaChecklist')
      tipoOrigem = intl.formatMessage({
        id: 'titulo.auditoriaChecklist',
      });
    else if (item.tipoOrigem === 'controlSelfAssessment')
      tipoOrigem = intl.formatMessage({
        id: 'labelControlSelfAssessment',
      });
    else if (item.pacote != null || item.centroCusto != null)
      tipoOrigem = capitalizeFirstLetter(this.props.resources.orcamento);
    else
      tipoOrigem = capitalizeFirstLetter(this.props.resources[item.tipoOrigem]);

    return <span>{tipoOrigem}</span>;
  }

  renderTipoAcao(item) {
    if (item.tipoAcao == 1) {
      return <FormattedMessage id="preventiva" />;
    } else if (item.tipoAcao == 2) {
      return <FormattedMessage id="corretiva" />;
    }
  }

  renderDataTerminoPrevista(item) {
    if (!item.dataTerminoPrevista) return;

    return (
      <span>
        {moment(item.dataTerminoPrevista).locale(this.props.lang).format('L')}
      </span>
    );
  }

  renderDataInicioPrevista(item) {
    if (!item.dataInicioPrevista) return;

    return (
      <span>
        {moment(item.dataInicioPrevista).locale(this.props.lang).format('L')}
      </span>
    );
  }

  renderDataTerminoRealizada(item) {
    const disabled = item.possuiFilhos || item.possuiAcaoOrigem;
    const allowEdit = item?.permissions?.allowEdit;

    if (this.props.atualizarAcoes && allowEdit) {
      return (
        <DatePicker
          placement="bottom"
          style={{ marginTop: 10, textAlign: 'center' }}
          timeFormat={false}
          autoFormatDate={true}
          model={{
            label: '',
            value: item.dataTerminoRealizada,
            requestChange: (value) =>
              this.handleChangeItem(item, 'dataTerminoRealizada', value),
          }}
          disabled={
            disabled || !item.podeEditarPercentualRealizadoEDatasRealizadas
          }
        />
      );
    } else {
      if (!item.dataTerminoRealizada) return;
      else {
        return (
          <span>
            {moment(item.dataTerminoRealizada)
              .locale(this.props.lang)
              .format('L')}
          </span>
        );
      }
    }
  }

  renderDataInicioRealizada(item) {
    const disabled = item.possuiFilhos || item.possuiAcaoOrigem;
    const allowEdit = item?.permissions?.allowEdit;

    if (this.props.atualizarAcoes && allowEdit) {
      return (
        <DatePicker
          placement="bottom"
          style={{ marginTop: 10, textAlign: 'center' }}
          timeFormat={false}
          autoFormatDate={true}
          model={{
            label: '',
            value: item.dataInicioRealizada,
            requestChange: (value) =>
              this.handleChangeItem(item, 'dataInicioRealizada', value),
          }}
          disabled={
            disabled || !item.podeEditarPercentualRealizadoEDatasRealizadas
          }
        />
      );
    } else {
      if (!item.dataInicioRealizada) return;
      else {
        return (
          <span>
            {moment(item.dataInicioRealizada)
              .locale(this.props.lang)
              .format('L')}
          </span>
        );
      }
    }
  }

  renderFarol(item) {
    return (
      <span>
        {item.farolAcao && (
          <span
            title={item.farolAcao.descricao}
            className="list-title-item list-item-comum"
          >
            <SvgIcon
              id={'acaoFarolid' + item.id}
              icon={item.farolAcao.icone}
              color={item.farolAcao.cor}
              colorHover={item.farolAcao.cor}
              size={item.farolAcao.tamanho}
              title={item.farolAcao.descricao}
            ></SvgIcon>
          </span>
        )}
      </span>
    );
  }

  renderCentroCusto(item) {
    return item.centroCusto && <a>{item.centroCusto.nome}</a>;
  }

  renderPacote(item) {
    return item.pacote && <a>{item.pacote.nome}</a>;
  }

  handleDeleteClick(item) {
    if (item.id > 0) {
      ContentManager.addContent(
        <ValidacaoRestricao
          url="/Acao/ValidarRestricoes"
          urlExclusao="/Acao/AtualizarStatusExcluido"
          item={item}
          afterRemove={this.props.afterRemove}
          onClose={this.handleCloseEdit}
        />
      );
    } else {
      this.props.afterRemove && this.props.afterRemove(item);
      this.handleCloseEdit();
    }
  }

  atualizarParametros() {
    if (location.search) {
      this.setState({
        parametroUrl: {
          dataInicioPrevista: this.state.dataInicioPrevista,
          dataTerminoPrevista: this.state.dataTerminoPrevista,
          dataInicioRealizada: this.state.dataInicioRealizada,
          dataTerminoRealizada: this.state.dataTerminoRealizada,
          codigo: this.state.codigo,
          descricao: this.state.descricao,
          farol: this.state.idFarol
            ? [
                {
                  id: this.state.idFarol,
                  descricao: this.state.farolDescricao,
                  icone: this.state.farolIcone,
                  cor: this.state.farolCor,
                },
              ]
            : null,
          areas: this.state.organizacaoId
            ? [
                {
                  id: this.state.organizacaoId,
                  nome: this.state.organizacaoNome,
                },
              ]
            : null,
          areaSubordinada: this.state.subordinadas
            ? this.state.subordinadas
            : false,
          itens: this.state.itemId
            ? [
                {
                  id: this.state.itemId,
                  nome: this.state.itemNome,
                },
              ]
            : null,
          listaStatus: this.state.statusId
            ? [
                {
                  id: this.state.statusId,
                  descricao: this.state.statusNome,
                },
              ]
            : null,
          responsaveis: this.state.responsavelId
            ? [
                {
                  id: this.state.responsavelId,
                  nome: this.state.responsavelNome,
                  descricao: this.state.responsavelNome,
                },
              ]
            : null,
          apenasAcoesModuloAtual: this.state.apenasAcoesModuloAtual
            ? this.state.apenasAcoesModuloAtual
            : false,
          exibirAcoesComoMembroEquipe: this.state.exibirAcoesComoMembroEquipe
            ? this.state.exibirAcoesComoMembroEquipe
            : false,
          widgetDashboardIndividual: this.state.widgetDashboardIndividual
            ? this.state.widgetDashboardIndividual
            : false,
          filtrarAcoesAreaColaborador: this.state.filtrarAcoesAreaColaborador
            ? this.state.filtrarAcoesAreaColaborador
            : false,
          filtrarAcoesEspelho: this.state.filtrarAcoesEspelho,
          centroCusto: this.state.centroCustoId
            ? { id: this.state.centroCustoId, nome: this.state.centroCustoNome }
            : null,
          pacote: this.state.pacoteId
            ? { id: this.state.pacoteId, nome: this.state.pacoteNome }
            : null,
          centrosSubordinados: this.state.centrosSubordinados
            ? this.state.centrosSubordinados
            : false,
          pacotesSubordinados: this.state.pacotesSubordinados
            ? this.state.pacotesSubordinados
            : false,
          tipoOrigem: this.state.tipoOrigemId
            ? { id: this.state.tipoOrigemId }
            : null,
          //organizacaoColaboradoresId: this.state.organizacaoColaboradoresId
          areaColaboradores: this.state.organizacaoColaboradoresId
            ? {
                id: this.state.organizacaoColaboradoresId,
                nome: this.state.organizacaoColaboradoresNome,
              }
            : null,
          tipoAcao: this.state.tipoAcaoId
            ? { id: this.state.tipoAcaoId }
            : null,
          desdobramento: this.state.desdobramentoId
            ? {
                id: this.state.desdobramentoId,
                descricao: this.state.desdobramentoDescricao,
              }
            : null,
          desdobramentoSubordinados: this.state.desdobramentoSubordinados
            ? this.state.desdobramentoSubordinados
            : false,
          incluirDesdobramento: this.state.incluirDesdobramento
            ? this.state.incluirDesdobramento
            : false,
          incluirImpactosIndiretos: this.state.incluirImpactosIndiretos
            ? this.state.incluirImpactosIndiretos
            : false,
          incluirItensFormula: this.state.incluirItensFormula
            ? this.state.incluirItensFormula
            : false,
          AreaSubordinadaColaboradores: this.state
            .subordinadasOrganizacaoColaboradores
            ? this.state.subordinadasOrganizacaoColaboradores
            : false,
          porque: this.state.porque,
          apenasAcoesOrigem: this.state.apenasAcoesOrigem
            ? this.state.apenasAcoesOrigem
            : false,
          nivelAcao: this.state.nivelAcao ? this.state.nivelAcao : null,
          matrizRisco: this.state.matrizRiscoId
            ? {
                id: this.state.matrizRiscoId,
                descricao: this.state.matrizRiscoDescricao,
              }
            : null,
          grausRisco: this.state.grausRiscoId
            ? this.state.grausRiscoId.map((id, index) => {
                return {
                  id: this.state.grausRiscoId[index],
                  descricao: this.state.grausRiscoNome[index],
                };
              })
            : null,
          auditorias: this.state.auditoriaId ? [{ id: this.state.auditoriaId, nome: this.state.auditoriaNome }] : this.state.auditoriasId
            ? this.state.auditoriasId.map((id, index) => {
                return {
                  id: this.state.auditoriasId[index],
                  nome: this.state.auditoriasNome[index],
                };
              })
            : null,
          camposCustomizados: this.state.camposCustomizados
            ? this.state.camposCustomizados
            : null,
          tags: this.state.tagsId
            ? this.state.tagsId.map((id, index) => {
                return {
                  id: this.state.tagsId[index],
                  descricao: this.state.tagsDescricao[index],
                };
              })
            : null,
          controlSelfAssessments: this.state.csasId
            ? this.state.csasId.map((id, index) => {
                return {
                  id: this.state.csasId[index],
                  nome: this.state.csasNome[index],
                };
              })
            : null,
          equipe: this.state.idColaboradorEquipe
            ? [
                {
                  id: this.state.idColaboradorEquipe,
                  nome: this.state.nomeColaboradorEquipe,
                },
              ]
            : null,
        },
      });
      let parametros = this.state.parametroUrl;
      return parametros;
    }
  }

  usandoKanban(usando) {
    this.setState({
      usandoKanban: usando,
    });
  }

  renderChecklist(item) {
    if (item.tarefas) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MdiIcon
            style={{ marginTop: 3, marginRight: 5 }}
            icon="checkbox-marked-outline"
          />
          {item.tarefas &&
            item.tarefas.filter((a) => a.concluida == true).length}
          /{item.tarefas && item.tarefas.length}
        </div>
      );
    }
  }

  updateParams(search) {
    let parameters = {
      ...search,
      statusId: search.status ? search.status.id : null,
      listaStatus: search.listaStatus ? search.listaStatus : null,
      responsavelId:
        search.responsaveis && search.responsaveis[0]
          ? search.responsaveis[0].id
          : null,
      areaId: search.areas && search.areas[0] ? search.areas[0].id : null,
      itemId: !this.props.filtroLocal && search.item ? search.item.id : null,
      item: search.item ? search.item : null,
      tipoOrigemId: search.tipoOrigem ? search.tipoOrigem.id : null,
      farol: search.farol ? search.farol : null,
      areasSubordinadas: search.areaSubordinada,
    };

    this.setState({ filtroAtualizado: true, defaultFilter: parameters });

    return parameters;
  }

  renderPontosProblematicos(item) {
    if (item.pontosProblematicos) {
      let descricaoPonto = '';
      item.pontosProblematicos.map(
        (ponto, index) => (descricaoPonto += ponto.descricao + '; ')
      );

      return (
        <div style={{ display: 'flex', alignItems: 'left' }}>
          {descricaoPonto}
        </div>
      );
    }
  }

  renderPercentualRealizado(item) {
    const disabled = item.possuiFilhos || item.possuiAcaoOrigem;

    return (
      <InputNumber
        style={{ marginTop: 10, textAlign: 'center' }}
        precision={2}
        disabled={
          !item ||
          !this.state.acaoConfiguracao.utilizarPercentualRealizado ||
          disabled ||
          !item.podeEditarPercentualRealizadoEDatasRealizadas
        }
        model={{
          value: item.percentualRealizado,
          requestChange: (value) =>
            this.handleChangeItem(item, 'percentualRealizado', value),
        }}
      />
    );
  }

  setNewUpdatedItem = (item, field, valor) => {
    if (!item) return;

    let newArray = [...this.state.updatedItems];
    let currentIndex = newArray.findIndex((i) => i.id === item.id);
    if (currentIndex < 0) {
      newArray.push(item);
    } else {
      newArray[currentIndex] = item;
    }
    this.setState({ updatedItems: newArray });
  };

  handleChangeItem = async (item, field, valor) => {
    try {
      item[field] = valor;

      item.editado = true;
      this.setNewUpdatedItem(item, field, valor);
    } catch (err) {
      errorHandler(err);
    }
  };

  handleSaveClick = async () => {
    await http
      .post(`Acao/SalvarAcoes`, {
        model: this.state.updatedItems,
      })
      .then((response) => {
        this.setState({
          updatedItems: [],
          saved: Math.random(),
        });
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  renderCreator(item) {
    if (!item.criadorAcao) return;

    return <span>{item.criadorAcao.nome}</span>;
  }

  renderCreationDate(item) {
    if (!item.dataCriacaoAcao) return;

    return (
      <span>
        {moment(item.dataCriacaoAcao).locale(this.props.lang).format('L')}
      </span>
    );
  }

  handleNewClick(isAcaoAprovacao) {
    ContentManager.addContent(
      <AcaoEdit
        mode="edit"
        parameters={{
          exibirLookups: true,
          ...this.state.parameters,
        }}
        endpoint={isAcaoAprovacao ? '/AcaoAprovacao' : '/Acao'}
        handleClose={
          isAcaoAprovacao
            ? () =>
                history.push({
                  pathname: '/gerenciar/acao',
                  search: `tab=1`,
                })
            : this.handleCloseEdit
        }
        isAcaoAprovacao={isAcaoAprovacao}
      />
    );
  }

  renderNewButton() {
    return (
      <UncontrolledButtonDropdown>
        <ButtonReactstrap
          color="primary"
          onClick={() => this.handleNewClick()}
          id="caret"
        >
          <MdiIcon icon="plus" className="pr-1" />
          {capitalizeFirstLetter(this.props.resources.acao)}
        </ButtonReactstrap>
        {this.state.acaoConfiguracao?.utilizarAprovacaoAcoes && (
          <>
            <DropdownToggle split color="primary" />
            <DropdownMenu>
              <DropdownItem onClick={() => this.handleNewClick(true)}>
                {capitalizeFirstLetter(
                  this.props.intl.formatMessage(
                    { id: 'acaoParaAprovar' },
                    { acao: this.props.resources.acao }
                  )
                )}
              </DropdownItem>
            </DropdownMenu>
          </>
        )}
      </UncontrolledButtonDropdown>
    );
  }

  renderBaselineInicial(item) {
    if (!item.baselineInicial) return;

    return (
      <span>
        {moment(item.baselineInicial).locale(this.props.lang).format('L')}
      </span>
    );
  }

  renderBaselineTermino(item) {
    if (!item.baselineTermino) return;

    return (
      <span>
        {moment(item.baselineTermino).locale(this.props.lang).format('L')}
      </span>
    );
  }

  renderTags(item) {
    return item.tags?.map((x) => (
      <Badge pill className="text-white" style={{ background: x.cor }}>
        {x.descricao}
      </Badge>
    ));
  }

  renderEquipe(item) {
    if (item.equipe) {
      const nomes = item.equipe.map(function (colaborador) {
        return colaborador.nome;
      });

      return <span>{nomes.join('; ')}</span>;
    }
  }

  renderGrauRisco(grauRisco, inerente, item) {
    if (!(grauRisco && grauRisco.cor)) return;
    const tooltipId = inerente
      ? 'colgrauRiscoInerente' + item.id
      : 'colgrauRisco' + item.id;
    return (
      <div
        className=" text-center"
        onMouseEnter={this.handleMouseOverGrauRisco.bind(this, item, inerente)}
        onMouseLeave={() => this.handleMouseOutGrauRisco(inerente)}
        id={tooltipId}
      >
        {grauRisco && grauRisco.cor && (
          <div>
            <MdiIcon
              icon="circle"
              color={grauRisco.cor}
              colorHover={grauRisco.cor}
              size={22}
            ></MdiIcon>
            {item.avaliacaoPendente && (
              <strong style={{ color: grauRisco.cor }} color={grauRisco.cor}>
                !
              </strong>
            )}
          </div>
        )}
      </div>
    );
  }

  renderDescricaoGrauRisco(item, field = 'descricaoGrauRisco') {
    return item ? (
      <div className=" text-center">{item[field]}</div>
    ) : (
      <div></div>
    );
  }

  renderStatusBaseLine(item) {
    if (!item.statusBaseLine) return;

    return <span>{item.statusBaseLine.descricao}</span>;
  }

  renderPopoverHintGrauRisco(item) {
    if (!item.avaliacaoPendente) {
      return;
    }

    return (
      <Popover
        placement="auto"
        isOpen={this.state.grauHintId == item.id}
        target={
          this.state.grauHintInerente
            ? 'colgrauRiscoInerente' + item.id
            : 'colgrauRisco' + item.id
        }
      >
        <PopoverBody style={{ width: 300 }}>
          <strong>
            {this.props.intl.formatMessage(
              { id: 'haAvaliacoesRiscoAtrasadas' },
              { risco: this.props.resources.risco }
            )}
          </strong>
        </PopoverBody>
      </Popover>
    );
  }

  render() {
    let {
      params,
      resources,
      intl,
      disabled = false,
      showModal,
      moduloAtual,
      filtroId = filtroId ? filtroId : 5,
      user,
      showToolbar,
      urlApresentacao,
      showSearchWidget,
      hideSearch = false,
      usarFiltroSalvo,
      showSearchAtualizarAcao,
      filtroLocal,
      showEstatisticaAcoes = true,
      parameters,
      atualizarAcoes,
      isConsultaExterna,
      showExport = true,
    } = this.props;

    let { parametroUrl, parametroSearchWidget } = this.state;
    let moduloLicenca = user.licenca.modulos.find((i) => {
      return i.ativo && i.id === 2;
    });

    if (moduloLicenca === undefined) {
      moduloLicenca = false;
    } else {
      moduloLicenca = true;
    }

    let createChildItems = [
      {
        label: capitalizeFirstLetter(resources.planodeacao),
        icon: 'chart-gantt',
        visible: (item) => item.item != null,
        onClick: this.handlePlanoAcaoClick,
      },
    ];

    let actions = [];
    if (this.props.params && this.props.params.itemId) {
      actions.concat(
        <DropdownItem onClick={this.handleOpenGantt}>
          <FormattedMessage id="label.gantt" />
        </DropdownItem>
      );
      actions.concat(
        <DropdownItem onClick={this.handleRemoveAcoes}>
          <FormattedMessage
            id="label.deletarTodoPlano"
            values={{ planodeacao: resources.planodeacao }}
          />
        </DropdownItem>
      );
    }

    const actionsToolBar = atualizarAcoes
      ? [
          <Button
            key={1}
            className="ml-2"
            onClick={this.handleSaveClick}
            type="primary"
          >
            <MdiIcon icon="content-save-outline" />
            {this.state.updatedItems.length > 0 && (
              <span className="ml-2">{this.state.updatedItems.length}</span>
            )}
          </Button>,
        ]
      : [];

    const possuiParametrosUrl =
      (this.state.responsavelId &&
        this.state.responsavelNome &&
        this.state.responsavelNome.length > 0) ||
      this.state.dataInicioPrevista ||
      this.state.dataTerminoPrevista ||
      this.state.dataInicioRealizada ||
      this.state.dataTerminoRealizada ||
      this.state.descricao ||
      this.state.codigo ||
      this.state.idFarol ||
      this.state.farolDescricao ||
      this.state.farolIcone ||
      this.state.farolCor ||
      this.state.organizacaoId ||
      this.state.organizacaoNome ||
      this.state.subordinadas ||
      this.state.itemId ||
      this.state.itemNome ||
      this.state.acaoId ||
      this.state.statusId ||
      this.state.statusNome ||
      this.state.organizacaoColaboradoresId ||
      this.state.organizacaoColaboradoresNome ||
      //this.state.usarFiltroSalvo ||
      this.state.apenasAcoesModuloAtual ||
      this.state.exibirAcoesComoMembroEquipe ||
      this.state.filtrarAcoesEspelho ||
      this.state.centroCustoId ||
      this.state.centroCustoNome ||
      this.state.pacoteId ||
      this.state.pacoteNome ||
      this.state.centrosSubordinados ||
      this.state.pacotesSubordinados ||
      this.state.tipoOrigemId ||
      this.state.somenteAcoesFilhas ||
      this.state.nivelAcao ||
      this.state.tipoAcaoId ||
      this.state.desdobramentoId ||
      this.state.desdobramentoDescricao ||
      this.state.desdobramentoSubordinados ||
      this.state.incluirDesdobramento ||
      this.state.incluirImpactosIndiretos ||
      this.state.incluirItensFormula ||
      this.state.subordinadasOrganizacaoColaboradores ||
      this.state.apenasAcoesOrigem ||
      this.state.widgetDashboardIndividual ||
      this.state.filtrarAcoesAreaColaborador ||
      this.state.acoes ||
      this.state.baselineInicial ||
      this.state.baselineTermino ||
      this.state.matrizRiscoId ||
      this.state.matrizRiscoDescricao ||
      this.state.grausRiscoId ||
      this.state.grausRiscoNome ||
      this.state.auditoriasId ||
      this.state.auditoriasNome ||
      this.state.auditoriaId ||
      this.state.auditoriaNome ||
      this.state.camposCustomizados ||
      this.state.tagsId ||
      this.state.tagsDescricao ||
      this.state.csasId ||
      this.state.csasNome ||
      this.state.idColaboradorEquipe ||
      this.state.nomeColaboradorEquipe;

    return (
      <div>
        {this.state.acaoConfiguracao != null && (
          <List
            usandoKanban={this.usandoKanban}
            kanban={this.props.kanban}
            exportApresentacao={this.props.exportApresentacao}
            formatoKanbanDefault={
              this.state.acaoConfiguracao.formatoKanbanDefault
            }
            usarIdCampCustomizado={true}
            columnsCard={this.state.columnsCard}
            columnsKanban={this.state.columnsKanban}
            allColumnsCard={this.state.allColumnsCard}
            allColumnsKanban={this.state.allColumnsKanban}
            handleDescricaoClick={this.handleDescricaoClick}
            showNew={!isConsultaExterna && !disabled}
            createChildItems={moduloAtual.id === 1 ? createChildItems : null}
            modelValue={
              urlApresentacao
                ? null
                : showSearchWidget
                ? parametroSearchWidget
                : parametroUrl
            }
            url="/Acao"
            editComponent={AcaoEdit}
            sort="UltimaVersao.CodigoOrdenacao"
            showDetail
            pageSize={20}
            tag={showModal ? 2013 : 2005}
            searchModel={{
              item: { id: this.state.itemId, nome: this.state.itemNome },
            }}
            onRemoveSaveFilter={true}
            {...this.props}
            parameters={
              parameters != null && parameters.isGanttSearch
                ? {
                    item: this.props.parameters?.itemId
                      ? { id: this.props.parameters?.itemId }
                      : null,
                    centroCusto: this.props.parameters?.centroCustoId
                      ? { id: this.props.parameters?.centroCustoId }
                      : null,
                    pacote: this.props.parameters?.pacoteId
                      ? { id: this.props.parameters?.pacoteId }
                      : null,
                    desdobramento: this.state.desdobramentoId
                      ? { id: this.state.desdobramentoId }
                      : null,
                    acao: this.state.acaoId ? { id: this.state.acaoId } : null,
                    itemId: this.props.parameters?.itemId,
                    centroCustoId: this.props.parameters?.centroCustoId,
                    pacoteId: this.props.parameters?.pacoteId,
                    desdobramentoId: this.state.desdobramentoId,
                    acaoId: this.state.acaoId,
                    naoFiltrarAcoesProjetosFilhos: true,
                  }
                : {
                    ...params,
                    ...parameters,
                    apenasAcoesModuloAtual: this.state.apenasAcoesModuloAtual,
                    exibirAcoesComoMembroEquipe: this.state
                      .exibirAcoesComoMembroEquipe,
                    widgetDashboardIndividual:
                      this.state.widgetDashboardIndividual,
                    filtrarAcoesAreaColaborador:
                      this.state.filtrarAcoesAreaColaborador,
                    filtrarAcoesEspelho:
                      this.props.parameters &&
                      this.props.parameters.filtrarAcoesVinculadas
                        ? true
                        : this.state.filtrarAcoesEspelho,
                    idFarol: this.state.idFarol,
                    farol: this.state.idFarol
                      ? [
                          {
                            id: this.state.idFarol,
                            descricao: this.state.farolDescricao,
                            icone: this.state.farolIcone,
                            cor: this.state.farolCor,
                          },
                        ]
                      : null,
                    areaId:
                      this.state.organizacaoId ?? (params && params.areaId),
                    areasSubordinadas:
                      this.state.subordinadas ??
                      (params && params.areasSubordinadas),
                    item:
                      this.state.itemId &&
                      (!parameters ||
                        !parameters.itemIdIgnorar ||
                        this.state.itemId != parameters.itemIdIgnorar)
                        ? { id: this.state.itemId, nome: this.state.itemNome }
                        : null,
                    acaoId: this.state.acaoId,
                    responsavelId:
                      this.state.responsavelId ??
                      (params && params.responsavelId),
                    responsaveis: this.state.responsavelId
                      ? [
                          {
                            id: this.state.responsavelId,
                            nome: this.state.responsavelNome,
                            descricao: this.state.responsavelNome,
                          },
                        ]
                      : null,
                    responsavelId: this.state.responsavelId
                      ? this.state.responsavelId
                      : this.props.selectedFilter?.valor?.responsavelId,
                    listaStatus: this.state.statusId
                      ? [
                          {
                            id: this.state.statusId,
                            descricao: this.state.statusNome,
                          },
                        ]
                      : null,
                    statusId: this.state.statusId
                      ? this.state.statusId
                      : this.props.selectedFilter?.valor?.status?.id,
                    desdobramentoId: this.state.desdobramentoId,
                    organizacaoColaboradoresId:
                      this.state.organizacaoColaboradoresId,
                    subordinadasOrganizacaoColaboradores:
                      this.state.subordinadasOrganizacaoColaboradores,
                    centroCustoId: this.state.centroCustoId,
                    pacoteId: this.state.pacoteId,
                    centrosSubordinados: this.state.centrosSubordinados,
                    pacotesSubordinados: this.state.pacotesSubordinados,
                    tipoOrigemId:
                      this.state.tipoOrigemId ??
                      (params && params.tipoOrigemId),
                    somenteAcoesFilhas: this.state.somenteAcoesFilhas,
                    incluirDesdobramento: this.state.incluirDesdobramento,
                    incluirImpactosIndiretos:
                      this.state.incluirImpactosIndiretos,
                    incluirItensFormula: this.state.incluirItensFormula,
                    exibirLookups: true,
                    tipoAcao: this.state.tipoAcaoId
                      ? { id: this.state.tipoAcaoId }
                      : null,
                    desdobramentoSubordinados:
                      this.state.desdobramentoSubordinados,
                    porque: this.state.porque,
                    apenasAcoesOrigem: this.state.apenasAcoesOrigem,
                    nivelAcao: this.state.nivelAcao,
                    acoes: this.state.acoes,
                    matrizRiscoId: this.state.matrizRiscoId,
                    matrizRiscoDescricao: this.state.matrizRiscoDescricao,
                    grausRiscoId: this.state.grausRiscoId,
                    grausRiscoNome: this.state.grausRiscoNome,
                    auditoriasId: this.state.auditoriasId,
                    auditoriasNome: this.state.auditoriasNome,
                    auditoriaId: this.state.auditoriaId,
                    auditoriaNome: this.state.auditoriaNome,
                    camposCustomizados: this.state.camposCustomizados,
                    tagsId: this.state.tagsId,
                    tagsDesricao: this.state.tagsDescricao,
                    csasId: this.state.csasId,
                    csasNome: this.state.csasNome,
                    equipe: this.state.idColaboradorEquipe
                      ? [
                          {
                            id: this.state.idColaboradorEquipe,
                            nome: this.state.nomeColaboradorEquipe,
                          },
                        ]
                      : null,
                  }
            }
            saved={
              this.props.refreshResultadoArea
                ? this.props.refreshResultadoArea
                : this.state.saved ||
                  this.props.refresh ||
                  this.props.refreshList
            }
            showSearch={hideSearch ? !hideSearch : showToolbar}
            searchComponent={
              showSearchWidget ? (
                <SearchWidget />
              ) : showModal ? (
                <SearchModal />
              ) : showSearchAtualizarAcao ? (
                <SearchAtualizarAcao />
              ) : (
                <Search />
              )
            }
            searchSchema={
              showSearchWidget
                ? SearchWidgetSchema
                : showModal
                ? SearchSchemaModal
                : SearchSchema
            }
            filtroId={filtroId ? filtroId : showModal ? 22 : 5}
            usarFiltroSalvo={
              urlApresentacao || filtroLocal
                ? false
                : usarFiltroSalvo && this.state.usarFiltroSalvo
            }
            actions={!disabled && actions}
            onDeleting={this.handleDeleteClick}
            showCustomFields={true}
            tipoItem={100}
            showEstatisticaAcoes={showEstatisticaAcoes}
            showArquivamento={this.state.acaoConfiguracao.podeArquivarAcoes}
            updateParams={!urlApresentacao ? this.updateParams : null}
            actionsToolBar={actionsToolBar}
            isConsultaExterna={isConsultaExterna}
            showMenu={!isConsultaExterna}
            showExport={showExport}
            hierarchy={false}
            selectedFilter={
              this.state.filtroAtualizado && possuiParametrosUrl
                ? this.state.defaultFilter
                : possuiParametrosUrl && {
                    valor: {
                      dataInicioPrevista: this.state.dataInicioPrevista,
                      dataTerminoPrevista: this.state.dataTerminoPrevista,
                      dataInicioRealizada: this.state.dataInicioRealizada,
                      dataTerminoRealizada: this.state.dataTerminoRealizada,
                      codigo: this.state.codigo,
                      descricao: this.state.descricao,
                      farol: this.state.idFarol
                        ? [
                            {
                              id: this.state.idFarol,
                              descricao: this.state.farolDescricao,
                              icone: this.state.farolIcone,
                              cor: this.state.farolCor,
                            },
                          ]
                        : null,
                      areas: this.state.organizacaoId
                        ? [
                            {
                              id: this.state.organizacaoId,
                              nome: this.state.organizacaoNome,
                            },
                          ]
                        : null,
                      areaSubordinada: this.state.subordinadas
                        ? this.state.subordinadas
                        : false,
                      itens: this.state.itemId
                        ? [
                            {
                              id: this.state.itemId,
                              nome: this.state.itemNome,
                            },
                          ]
                        : null,
                      listaStatus: this.state.statusId
                        ? [
                            {
                              id: this.state.statusId,
                              descricao: this.state.statusNome,
                            },
                          ]
                        : null,
                      responsaveis: this.state.responsavelId
                        ? [
                            {
                              id: this.state.responsavelId,
                              nome: this.state.responsavelNome,
                              descricao: this.state.responsavelNome,
                            },
                          ]
                        : null,
                      apenasAcoesModuloAtual: this.state.apenasAcoesModuloAtual
                        ? this.state.apenasAcoesModuloAtual
                        : false,
                        exibirAcoesComoMembroEquipe: this.state.exibirAcoesComoMembroEquipe
                          ? this.state.exibirAcoesComoMembroEquipe
                          : false,
                      widgetDashboardIndividual: this.state
                        .widgetDashboardIndividual
                        ? this.state.widgetDashboardIndividual
                        : false,
                      filtrarAcoesAreaColaborador: this.state
                        .filtrarAcoesAreaColaborador
                        ? this.state.filtrarAcoesAreaColaborador
                        : false,
                      filtrarAcoesEspelho: this.state.filtrarAcoesEspelho,
                      centroCusto: this.state.centroCustoId
                        ? {
                            id: this.state.centroCustoId,
                            nome: this.state.centroCustoNome,
                          }
                        : null,
                      pacote: this.state.pacoteId
                        ? {
                            id: this.state.pacoteId,
                            nome: this.state.pacoteNome,
                          }
                        : null,
                      centrosSubordinados: this.state.centrosSubordinados
                        ? this.state.centrosSubordinados
                        : false,
                      pacotesSubordinados: this.state.pacotesSubordinados
                        ? this.state.pacotesSubordinados
                        : false,
                      tipoOrigem: this.state.tipoOrigemId
                        ? { id: this.state.tipoOrigemId }
                        : null,
                      areaColaboradores: this.state.organizacaoColaboradoresId
                        ? {
                            id: this.state.organizacaoColaboradoresId,
                            nome: this.state.organizacaoColaboradoresNome,
                          }
                        : null,
                      tipoAcao: this.state.tipoAcaoId
                        ? { id: this.state.tipoAcaoId }
                        : null,
                      desdobramento: this.state.desdobramentoId
                        ? {
                            id: this.state.desdobramentoId,
                            descricao: this.state.desdobramentoDescricao,
                          }
                        : null,
                      desdobramentoSubordinados: this.state
                        .desdobramentoSubordinados
                        ? this.state.desdobramentoSubordinados
                        : false,
                      incluirDesdobramento: this.state.incluirDesdobramento
                        ? this.state.incluirDesdobramento
                        : false,
                      incluirImpactosIndiretos: this.state
                        .incluirImpactosIndiretos
                        ? this.state.incluirImpactosIndiretos
                        : false,
                      incluirItensFormula: this.state.incluirItensFormula
                        ? this.state.incluirItensFormula
                        : false,
                      AreaSubordinadaColaboradores: this.state
                        .subordinadasOrganizacaoColaboradores
                        ? this.state.subordinadasOrganizacaoColaboradores
                        : false,
                      porque: this.state.porque,
                      apenasAcoesOrigem: this.state.apenasAcoesOrigem
                        ? this.state.apenasAcoesOrigem
                        : false,
                      nivelAcao: this.state.nivelAcao
                        ? this.state.nivelAcao
                        : null,
                      somenteAcoesFilhas: this.state.somenteAcoesFilhas,
                      matrizRisco: this.state.matrizRiscoId
                        ? {
                            id: this.state.matrizRiscoId,
                            descricao: this.state.matrizRiscoDescricao,
                          }
                        : null,
                      grausRisco: this.state.grausRiscoId
                        ? this.state.grausRiscoId.map((id, index) => {
                            return {
                              id: this.state.grausRiscoId[index],
                              descricao: this.state.grausRiscoNome[index],
                            };
                          })
                        : null,
                      auditorias: this.state.auditoriaId ? [{id: this.state.auditoriaId, nome: this.state.auditoriaNome}] : this.state.auditoriasId
                        ? this.state.auditoriasId.map((id, index) => {
                            return {
                              id: this.state.auditoriasId[index],
                              nome: this.state.auditoriasNome[index],
                            };
                          })
                        : null,
                      camposCustomizados: this.state.camposCustomizados
                        ? this.state.camposCustomizados
                        : null,
                      tags: this.state.tagsId
                        ? this.state.tagsId.map((id, index) => {
                            return {
                              id: this.state.tagsId[index],
                              descricao: this.state.tagsDescricao[index],
                            };
                          })
                        : null,
                      controlSelfAssessments: this.state.csasId
                        ? this.state.csasId.map((id, index) => {
                            return {
                              id: this.state.csasId[index],
                              nome: this.state.csasNome[index],
                            };
                          })
                        : null,
                      equipe: this.state.idColaboradorEquipe
                        ? [
                            {
                              id: this.state.idColaboradorEquipe,
                              nome: this.state.nomeColaboradorEquipe,
                            },
                          ]
                        : null,
                        acoes: this.state.acoes,
                    },
                  }
            }
            openLastFilter={!possuiParametrosUrl}
            ehAcao={true}
          >
            <ListColumn
              headerText={intl.formatMessage({ id: 'item' })}
              valueField="nomeItem"
              sortField="Item.UltimaVersao.Nome"
              default
            />
            <ListColumn
              center
              tree={false}
              headerText={intl.formatMessage({ id: 'codigo' })}
              valueField="codigo"
              sortField="UltimaVersao.CodigoOrdenacao"
              default
            />
            <ListColumn
              headerText={capitalizeFirstLetter(
                intl.formatMessage({ id: 'descricao' })
              )}
              valueField="descricao"
              sortField="UltimaVersao.Descricao"
              valueFunction={this.renderDescricao}
              popoverFunction={this.renderPopoverHintAcao}
              default
            />
            <ListColumn
              visible={
                moduloLicenca &&
                (!this.form.component.apenasAcoesModuloAtual ||
                  moduloAtual.id === 2)
              }
              headerText={intl.formatMessage(
                { id: 'centroDeCusto' },
                { centrocusto: resources.centrocusto }
              )}
              valueField="centroCusto"
              sortField="centroCusto"
              valueFunction={this.renderCentroCusto}
              default
            />
            <ListColumn
              visible={
                moduloLicenca &&
                (!this.form.component.apenasAcoesModuloAtual ||
                  moduloAtual.id === 2)
              }
              headerText={intl.formatMessage({ id: 'pacoteConta' })}
              valueField="pacote"
              sortField="pacote"
              valueFunction={this.renderPacote}
              default
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.responsavel)}
              valueField="responsavel"
              valueFunction={this.renderResponsavel}
              sortField="UltimaVersao.Responsavel.Nome"
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'dataInicioPrevista' })}
              valueField="dataInicioPrevista"
              sortField="UltimaVersao.dataInicioPrevista"
              valueFunction={this.renderDataInicioPrevista}
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'dataTerminoPrevista' })}
              valueField="dataTerminoPrevista"
              sortField="UltimaVersao.dataTerminoPrevista"
              valueFunction={this.renderDataTerminoPrevista}
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'dataInicioRealizada' })}
              valueField="dataInicioRealizada"
              sortField="UltimaVersao.dataInicioRealizada"
              valueFunction={this.renderDataInicioRealizada}
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'dataTerminoRealizada' })}
              valueField="dataTerminoRealizada"
              sortField="UltimaVersao.dataTerminoRealizada"
              valueFunction={this.renderDataTerminoRealizada}
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'status' })}
              valueField="status"
              valueFunction={this.renderStatus}
              default
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.farol)}
              valueField="farolAcao"
              sortField="UltimaVersao.FarolAcao"
              valueFunction={this.renderFarol}
              default
            />
            <ListColumn
              headerText={capitalizeFirstLetter(resources.area)}
              valueField="area"
              sortField="Item.UltimaVersao.Area.Nome"
            />
            <ListColumn
              visible={this.state.acaoConfiguracao.ocultarPercentualPrevisto}
              headerText={intl.formatMessage(
                { id: 'label.percentualPrevisto' },
                { percprevisto: resources.percprevisto }
              )}
              valueField="percentualPrevisto"
              sortField="UltimaVersao.percentualPrevisto"
            />
            <ListColumn
              visible={this.state.acaoConfiguracao.ocultarPercentualRealizado}
              headerText={intl.formatMessage(
                { id: 'percentualRealizado' },
                { percrealizado: resources.percrealizado }
              )}
              valueField="percentualRealizado"
              {...(atualizarAcoes && {
                valueFunction: this.renderPercentualRealizado,
              })}
              sortField="UltimaVersao.percentualRealizado"
            />
            <ListColumn
              visible={this.state.acaoConfiguracao.ocultarInvestimentoPrevisto}
              headerText={intl.formatMessage(
                { id: 'investimentoPrevisto' },
                { investimento: resources.investimento }
              )}
              valueField="investimentoPrevisto"
              sortField="UltimaVersao.investimentoPrevisto"
            />
            <ListColumn
              visible={this.state.acaoConfiguracao.ocultarInvestimentoRealizado}
              headerText={intl.formatMessage(
                { id: 'investimentoRealizado' },
                { investimento: resources.investimento }
              )}
              valueField="investimentoRealizado"
              sortField="UltimaVersao.investimentoRealizado"
            />
            <ListColumn
              headerText={intl.formatMessage(
                { id: 'custeioPrevisto' },
                { custeio: resources.custeio }
              )}
              valueField="custeioPrevisto"
              sortField="UltimaVersao.custeioPrevisto"
            />
            <ListColumn
              headerText={intl.formatMessage(
                { id: 'custeioRealizado' },
                { custeio: resources.custeio }
              )}
              valueField="custeioRealizado"
              sortField="UltimaVersao.custeioRealizado"
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'label.duracao' })}
              valueField="duracao"
              sortField="UltimaVersao.duracao"
            />
            <ListColumn
              visible={this.state.acaoConfiguracao.ocultarEsforco}
              headerText={intl.formatMessage({ id: 'esforco' })}
              valueField="esforco"
              sortField="UltimaVersao.esforco"
            />
            <ListColumn
              headerText={capitalizeFirstLetter(
                intl.formatMessage({ id: 'label.itensVinculados' })
              )}
              valueField="idIntegracaoItensVinculados"
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'tipoOrigem' })}
              valueField="tipoOrigem"
              valueFunction={(value) => this.renderTipoOrigem(value, intl)}
              default
            />
            <ListColumn
              headerText={intl.formatMessage(
                { id: 'tipoAcao' },
                { acao: resources.acao }
              )}
              valueField="tipoAcao"
              valueFunction={this.renderTipoAcao}
              default
            />
            <ListColumn
              headerText={intl.formatMessage(
                { id: 'ganhosMeta' },
                { meta: resources.meta }
              )}
              valueField="ganhosMeta"
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'ganhosRealizado' })}
              valueField="ganhosRealizado"
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'label.desvio' })}
              valueField="desvioGanho"
              default
            />
            <ListColumn
              center
              visible={this.state.acaoConfiguracao.habilitarChecklistTarefas}
              headerText={intl.formatMessage({ id: 'checklistTarefas' })}
              valueField="checklist"
              valueFunction={this.renderChecklist}
              default
            />
            <ListColumn
              center
              visible={this.state.acaoConfiguracao.utilizarPorque}
              headerText={capitalizeFirstLetter(resources.porque)}
              valueField="porque"
              default
            />
            <ListColumn
              visible={this.state.acaoConfiguracao.utilizarCausasImpactadas}
              headerText={intl.formatMessage(
                { id: 'pontosProblematicos' },
                { pontosproblematicos: resources.pontosproblematicos }
              )}
              valueField="pontosProblematicos"
              valueFunction={this.renderPontosProblematicos}
            />
            <ListColumn
              headerText={intl.formatMessage(
                { id: 'nivelAcao' },
                { acao: resources.acao }
              )}
              valueField="nivelAcao"
            />
            <ListColumn
              headerText={intl.formatMessage(
                { id: 'criadorAcao' },
                { acao: resources.acao }
              )}
              valueField="criadorAcao"
              valueFunction={this.renderCreator}
            />
            <ListColumn
              headerText={intl.formatMessage(
                { id: 'dataCriacaoAcao' },
                { acao: resources.acao }
              )}
              valueField="dataCriacaoAcao"
              valueFunction={this.renderCreationDate}
            />
            <ListColumn
              visible={this.state.acaoConfiguracao.habilitarBaseline}
              headerText={intl.formatMessage({ id: 'baselineInicial' })}
              valueField="baselineInicial"
              sortField="UltimaVersao.baselineInicial"
              valueFunction={this.renderBaselineInicial}
            />
            <ListColumn
              visible={this.state.acaoConfiguracao.habilitarBaseline}
              headerText={intl.formatMessage({ id: 'baselineTermino' })}
              valueField="baselineTermino"
              sortField="UltimaVersao.baselineTermino"
              valueFunction={this.renderBaselineTermino}
            />
            <ListColumn
              visible={this.state.acaoConfiguracao.vincularAcaoNaMatriz}
              headerText={intl.formatMessage(
                { id: 'matrizRisco' },
                { risco: resources.risco }
              )}
              valueField="descricaoMatrizRisco"
            />
            <ListColumn
              visible={this.state.acaoConfiguracao.podeVisualizarTag}
              headerText={intl.formatMessage(
                { id: 'tags' },
                { tags: resources.tags }
              )}
              valueField="tags"
              valueFunction={this.renderTags}
              default
            />
            <ListColumn
              headerText={intl.formatMessage(
                { id: 'grauRisco' },
                { risco: resources.risco }
              )}
              valueField="grauRisco"
              visible={
                this.state.acaoConfiguracao
                  .exibirGrauDescricaoAcaoVinculadaRisco
              }
              valueFunction={(item) =>
                this.renderGrauRisco(item && item.grauRisco, false, item)
              }
              popoverFunction={this.renderPopoverHintGrauRisco}
              default
            />
            <ListColumn
              headerText={`${intl.formatMessage({
                id: 'descricao',
              })} ${intl.formatMessage(
                { id: 'grauRisco' },
                { risco: resources.risco }
              )}`}
              visible={
                this.state.acaoConfiguracao
                  .exibirGrauDescricaoAcaoVinculadaRisco
              }
              valueField="descricaoGrauRisco"
              valueFunction={(item) =>
                this.renderDescricaoGrauRisco(item, 'descricaoGrauRisco')
              }
              default
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'equipe' })}
              valueField="equipe"
              valueFunction={this.renderEquipe}
              visible={this.state.acaoConfiguracao.habilitarEquipe}
            />
            <ListColumn
              headerText={intl.formatMessage({ id: 'auditoria' })}
              valueField="auditorias"
              default
            />
            <ListColumn
              headerText={intl.formatMessage({
                id: 'labelControlSelfAssessment',
              })}
              valueField="controlSelfAssessments"
              default
            />

            <ListColumn
              headerText={`${intl.formatMessage({
                id: 'labelStatusBaseLine',
              })} `}
              visible={this.state.acaoConfiguracao.exibirStatusBaseLineTermino}
              valueField="statusBaseLineTermino"
              valueFunction={(item) => this.renderStatusBaseLine(item)}
              default
            />
          </List>
        )}
        {!this.state.usandoKanban && <Legenda mode={this.props.mode}></Legenda>}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    moduloAtual: state.user.moduloAtual,
    user: state.user,
    lang: state.user && state.user.idioma,
    viewType: state.viewType,
  };
}
export default injectIntl(connect(mapStateToProps)(AcaoList));
