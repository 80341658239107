import React from 'react';
import qs from 'query-string';
import { autobind } from 'core-decorators';
import {
  Badge,
  Popover,
  PopoverBody,
  UncontrolledTooltip,
} from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import List from 'src/componentes/list';
import ListColumn from 'src/componentes/list/list-column';
import MdiIcon from 'src/componentes/mdi-icon';
import { capitalizeFirstLetter } from 'src/utils/string';
import { getConfiguration } from 'src/services/commonRequests';
import history from 'src/history';
import { MODULOS, TIPO_INTERACAO_SOPHIE } from 'src/utils/constants';

import Legenda from '../../legenda-farol-revisao';
import Search from './Search';
import SearchModal from './SearchModal';
import SearchSchema from './SearchSchema';
import SearchSchemaModal from './SearchSchemaModal';
import { formatNumber } from 'src/utils/number';
import AssistenteVirtual from 'src/components/IA/ChatGPT/AssistenteVirtual';
import Button from 'src/componentes/button';
import ContentManager from 'src/componentes/content-manager';
import RiscoEditView from 'src/paginas/gestao-risco/risco/RiscoEditView';
import DropdownItemRisco from './DropdownItemRisco';
import http from 'src/services/httpService';
import LoadingContainer from 'src/componentes/loading-container';
import css from 'src/utils/css';
import IconButton from 'src/componentes/icon-button';

const classes = css`
  .popoverBody {
    max-height: 600px;
    max-width: 600px;
    overflow-y: auto;
    overflow-x: auto;
  }
`;

@autobind
class Risco extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: 0,
      isLoadingConfigs: true,
      isLoadingTiposSeveridades: true,
      grauHintId: 0,
      grauInerenteHintId: 0,
      comentarioHintId: null,
      grauHintInerente: false,
      closeModal: false,
      parametroUrl: null,
      refreshFilterChip: 0,
      changedColumns: 0,
      camposCustomizadosUrl: this.getCustomizadoDaUrl(),
      queryString: {
        areaId:
          window.location && qs.parse(window.location.search).areaId
            ? decodeURIComponent(qs.parse(window.location.search).areaId).split(
                ','
              )
            : null,
        areaNome:
          window.location && qs.parse(window.location.search).areaNome
            ? decodeURIComponent(
                qs.parse(window.location.search).areaNome
              ).split(',')
            : null,
        areasSubordinadas:
          window.location && qs.parse(window.location.search).areasSubordinadas
            ? qs.parse(window.location.search).areasSubordinadas
            : null,
        nome:
          window.location && qs.parse(window.location.search).nome
            ? qs.parse(window.location.search).nome
            : null,
        statusId:
          window.location && qs.parse(window.location.search).statusId
            ? qs.parse(window.location.search).statusId
            : null,
        statusDescricao:
          window.location && qs.parse(window.location.search).statusDescricao
            ? qs.parse(window.location.search).statusDescricao
            : null,
        idIntegracao:
          window.location && qs.parse(window.location.search).idIntegracao
            ? qs.parse(window.location.search).idIntegracao
            : null,
        processoId:
          window.location && qs.parse(window.location.search).processoId
            ? qs.parse(window.location.search).processoId
            : null,
        processoNome:
          window.location && qs.parse(window.location.search).processoNome
            ? decodeURIComponent(
                qs.parse(window.location.search).processoNome
              ).split(',')
            : null,
        incluirSubprocessos:
          window.location &&
          qs.parse(window.location.search).incluirSubprocessos
            ? qs.parse(window.location.search).incluirSubprocessos
            : null,
        modeloRiscoId:
          window.location && qs.parse(window.location.search).modeloRiscoId
            ? qs.parse(window.location.search).modeloRiscoId
            : null,
        matrizRiscoId:
          window.location && qs.parse(window.location.search).matrizRiscoId
            ? qs.parse(window.location.search).matrizRiscoId
            : null,
        grauRiscoId:
          window.location && qs.parse(window.location.search).grauRiscoId
            ? qs.parse(window.location.search).grauRiscoId
            : null,
        tipoRiscoId:
          window.location && qs.parse(window.location.search).tipoRiscoId
            ? qs.parse(window.location.search).tipoRiscoId
            : null,
        responsavelId:
          window.location && qs.parse(window.location.search).responsavelId
            ? qs.parse(window.location.search).responsavelId
            : null,
        responsavelNome:
          window.location && qs.parse(window.location.search).responsavelNome
            ? qs.parse(window.location.search).responsavelNome
            : null,
        grausRiscoInerenteId:
          window.location &&
          qs.parse(window.location.search).grausRiscoInerenteId
            ? qs.parse(window.location.search).grausRiscoInerenteId.split(',')
            : null,
        grausRiscoInerenteNome:
          window.location &&
          qs.parse(window.location.search).grausRiscoInerenteNome
            ? qs.parse(window.location.search).grausRiscoInerenteNome.split(',')
            : null,
        severidadeInerenteId:
          window.location &&
          qs.parse(window.location.search).severidadeInerenteId
            ? qs.parse(window.location.search).severidadeInerenteId.split(',')
            : null,
        severidadeInerenteNome:
          window.location &&
          qs.parse(window.location.search).severidadeInerenteNome
            ? qs.parse(window.location.search).severidadeInerenteNome.split(',')
            : null,
        probabilidadeInerenteId:
          window.location &&
          qs.parse(window.location.search).probabilidadeInerenteId
            ? qs
                .parse(window.location.search)
                .probabilidadeInerenteId.split(',')
            : null,
        probabilidadeInerenteNome:
          window.location &&
          qs.parse(window.location.search).probabilidadeInerenteNome
            ? qs
                .parse(window.location.search)
                .probabilidadeInerenteNome.split(',')
            : null,
        grausRiscoResidualId:
          window.location &&
          qs.parse(window.location.search).grausRiscoResidualId
            ? decodeURIComponent(
                qs.parse(window.location.search).grausRiscoResidualId
              ).split(',')
            : null,
        grausRiscoResidualNome:
          window.location &&
          qs.parse(window.location.search).grausRiscoResidualNome
            ? decodeURIComponent(
                qs.parse(window.location.search).grausRiscoResidualNome
              ).split(',')
            : null,
        severidadeResidualId:
          window.location &&
          qs.parse(window.location.search).severidadeResidualId
            ? qs.parse(window.location.search).severidadeResidualId.split(',')
            : null,
        severidadeResidualNome:
          window.location &&
          qs.parse(window.location.search).severidadeResidualNome
            ? qs.parse(window.location.search).severidadeResidualNome.split(',')
            : null,
        probabilidadeResidualId:
          window.location &&
          qs.parse(window.location.search).probabilidadeResidualId
            ? qs
                .parse(window.location.search)
                .probabilidadeResidualId.split(',')
            : null,
        probabilidadeResidualNome:
          window.location &&
          qs.parse(window.location.search).probabilidadeResidualNome
            ? qs
                .parse(window.location.search)
                .probabilidadeResidualNome.split(',')
            : null,
        desconsiderarStatusConcluido:
          window.location &&
          qs.parse(window.location.search).desconsiderarStatusConcluido
            ? qs.parse(window.location.search).desconsiderarStatusConcluido
            : null,
        desconsiderarStatusCancelado:
          window.location &&
          qs.parse(window.location.search).desconsiderarStatusCancelado
            ? qs.parse(window.location.search).desconsiderarStatusCancelado
            : null,
        causasRiscoId:
          window.location && qs.parse(window.location.search).causasRiscoId
            ? decodeURIComponent(
                qs.parse(window.location.search).causasRiscoId
              ).split(',')
            : null,
        causasRiscoNome:
          window.location && qs.parse(window.location.search).causasRiscoNome
            ? decodeURIComponent(
                qs.parse(window.location.search).causasRiscoNome
              ).split(',')
            : null,
        consequenciasRiscoId:
          window.location &&
          qs.parse(window.location.search).consequenciasRiscoId
            ? decodeURIComponent(
                qs.parse(window.location.search).consequenciasRiscoId
              ).split(',')
            : null,
        consequenciasRiscoNome:
          window.location &&
          qs.parse(window.location.search).consequenciasRiscoNome
            ? decodeURIComponent(
                qs.parse(window.location.search).consequenciasRiscoNome
              ).split(',')
            : null,
        tratamentoId:
          window.location && qs.parse(window.location.search).tratamentoId
            ? qs.parse(window.location.search).tratamentoId
            : null,
        tratamentoNome:
          window.location && qs.parse(window.location.search).tratamentoNome
            ? qs.parse(window.location.search).tratamentoNome
            : null,
        statusAvaliacaoId:
          window.location && qs.parse(window.location.search).statusAvaliacaoId
            ? qs.parse(window.location.search).statusAvaliacaoId
            : null,
        statusAvaliacaoNome:
          window.location &&
          qs.parse(window.location.search).statusAvaliacaoNome
            ? qs.parse(window.location.search).statusAvaliacaoNome
            : null,
        farolPontoControleId:
          window.location &&
          qs.parse(window.location.search).farolPontoControleId
            ? qs.parse(window.location.search).farolPontoControleId
            : null,
        farolPontoControleNome:
          window.location &&
          qs.parse(window.location.search).farolPontoControleNome
            ? qs.parse(window.location.search).farolPontoControleNome
            : null,
      },
    };
  }

  componentDidMount() {
    getConfiguration('ItemConfiguracaoRisco')((configuracao) => {
      this.setState({ ...this.state, configuracao });
      this.createColumns();
    })();
    this.getTiposSeveridades();
    this.atualizarParametros();
  }

  async getTiposSeveridades() {
    if (!this.showColumn('tipoSeveridade')) {
      this.setState({ isLoadingTiposSeveridades: false });
      return;
    }

    try {
      await http
        .post(`/TipoSeveridade/List`, {
          parameters: { buscarExcluidos: false },
          pageSize: 1000,
        })
        .then((response) => {
          if (response?.data?.list?.length === 0)
            this.setState({ isLoadingTiposSeveridades: false });
          else
            this.setState(
              {
                columnsDinamic: response.data.list.map((tipo, index) => ({
                  headerText: tipo?.nome,
                  visible: true,
                  default: false,
                  popoverFunction: (item) => {
                    const valorTipoSeveridade =
                      item.valoresTipoSeveridade?.find(
                        (v) => v?.tipoSeveridade?.id == tipo.id
                      );

                    return this.popoverTipoSeveridade(
                      item,
                      valorTipoSeveridade,
                      index
                    );
                  },
                  valueField: `severidade${index}`,
                  valueFunction: (item) => {
                    const valorTipoSeveridade =
                      item.valoresTipoSeveridade?.find(
                        (v) => v?.tipoSeveridade?.id == tipo.id
                      );

                    const idComentario = this.obterIdPopoverTipoSeveridade(
                      item,
                      index
                    );

                    return (
                      <div
                        style={{
                          cursor: valorTipoSeveridade?.comentario
                            ? 'pointer'
                            : 'default',
                        }}
                        onClick={() =>
                          valorTipoSeveridade?.comentario &&
                          this.handleMouseClickTipoSeveridade(item, index)
                        }
                        onMouseEnter={() =>
                          this.handleMouseOverTipoSeveridade(item, index)
                        }
                        onMouseLeave={() =>
                          valorTipoSeveridade?.comentario &&
                          this.handleMouseOutPopOver()
                        }
                        id={idComentario}
                      >
                        <span
                          style={{
                            fontWeight:
                              this.state.comentarioClickHintId == idComentario
                                ? 'bold'
                                : 'normal',
                          }}
                        >
                          {valorTipoSeveridade
                            ? valorTipoSeveridade.severidade?.nome
                            : '-'}
                        </span>
                      </div>
                    );
                  },
                })),
              },
              () => this.setState({ isLoadingTiposSeveridades: false })
            );
        });
    } catch {
      this.setState({
        isLoadingTiposSeveridades: false,
      });
    }
  }

  getCustomizadoDaUrl() {
    const queryParams = qs.parse(window.location.search);
    const queryKeys = Object.keys(queryParams);

    const customFields = queryKeys.filter((key) =>
      key.startsWith('campoCustomizado.')
    );

    const customFieldsObject = {};
    customFields.forEach((field) => {
      const newField = field.replace('campoCustomizado.', '');
      customFieldsObject[newField] = queryParams[field];
    });

    return customFieldsObject;
  }

  renderResponsavel(item) {
    return (
      <div>
        <p style={{ margin: '0 0 3px' }}>{item.area}</p>
        <p>{item.responsavel}</p>
      </div>
    );
  }

  renderNome(item, callback) {
    if (!item.nome) {
      return;
    }

    return (
      <a
        tabIndex="-1"
        onClick={this.handleNomeClick.bind(this, item, callback)}
        style={{ cursor: 'pointer' }}
      >
        {item.nome}
      </a>
    );
  }

  handleNomeClick(item) {
    ContentManager.addContent(
      <RiscoEditView
        mode="edit"
        handleClose={this.closeModal}
        item={item}
        {...this.props}
      />
    );
  }

  closeModal(saved) {
    if (this.props.ignoreCloseModal) return;

    if (saved) {
      this.setState({
        closeModal: true,
        saved: ++this.state.saved,
      });
      this.props.refreshList && this.props.refreshList(saved);
    } else {
      this.setState({
        closeModal: true,
      });
    }
  }

  handleMouseOver(item, inerente) {
    if (inerente)
      this.setState({
        grauInerenteHintId: item.id,
      });
    else
      this.setState({
        grauHintId: item.id,
      });
  }

  handleMouseOut(inerente) {
    if (inerente) this.setState({ grauInerenteHintId: 0 });
    else this.setState({ grauHintId: 0 });
  }

  popoverTipoSeveridade(item, valorTipoSeveridade, index = 0) {
    const idComentario = this.obterIdPopoverTipoSeveridade(item, index);

    if (!valorTipoSeveridade?.comentario) return;

    return (
      <Popover
        placement="left"
        isOpen={this.state.comentarioHintId == idComentario}
        target={idComentario}
      >
        <PopoverBody className={classes.popoverBody}>
          <span>{valorTipoSeveridade?.comentario}</span>
        </PopoverBody>
      </Popover>
    );
  }

  handleMouseOverTipoSeveridade(item, index = 0) {
    const idComentario = this.obterIdPopoverTipoSeveridade(item, index);
    this.setState({
      comentarioHintId: idComentario,
      comentarioClickHintId:
        idComentario != this.state.comentarioClickHintId
          ? null
          : this.state.comentarioClickHintId,
    });
  }

  obterIdPopoverTipoSeveridade(item, index = 0) {
    return `colseveridade${index}${item.id}`;
  }

  handleMouseOverComentario(item, severidade, inerente) {
    const idComentario = this.obterIdPopoverComentarioSeveridade(
      item,
      severidade,
      inerente
    );

    this.setState({
      comentarioHintId: idComentario,
      comentarioClickHintId:
        idComentario != this.state.comentarioClickHintId
          ? null
          : this.state.comentarioClickHintId,
    });
  }

  handleMouseClickTipoSeveridade(item, index) {
    const idTipoSeveridade = this.obterIdPopoverTipoSeveridade(item, index);

    if (this.state.comentarioClickHintId == idTipoSeveridade)
      this.setState({ comentarioClickHintId: null });
    else
      this.setState({
        comentarioClickHintId: idTipoSeveridade,
      });
  }

  handleMouseClickComentario(item, severidade, inerente) {
    const idComentario = this.obterIdPopoverComentarioSeveridade(
      item,
      severidade,
      inerente
    );

    if (this.state.comentarioClickHintId == idComentario)
      this.setState({ comentarioClickHintId: null });
    else
      this.setState({
        comentarioClickHintId: idComentario,
      });
  }

  handleMouseOutPopOver() {
    if (this.state.comentarioClickHintId) return;

    this.setState({ comentarioHintId: null });
  }

  renderPopoverHintGrauRiscoInerente(item) {
    if (
      !item.nome ||
      this.state.grauInerenteHintId != item.id ||
      !item.avaliacaoPendente
    ) {
      return;
    }

    return (
      <Popover
        placement="auto"
        isOpen={this.state.grauInerenteHintId == item.id}
        target={'colgrauRiscoInerente' + item.id}
      >
        <PopoverBody className={classes.popoverBody}>
          <strong>
            {this.props.intl.formatMessage(
              { id: 'haAvaliacoesRiscoAtrasadas' },
              { risco: this.props.resources.risco }
            )}
          </strong>
        </PopoverBody>
      </Popover>
    );
  }

  renderPopoverHintGrauRisco(item) {
    if (
      !item.nome ||
      this.state.grauHintId != item.id ||
      !item.avaliacaoPendente
    ) {
      return;
    }

    return (
      <Popover
        placement="auto"
        isOpen={this.state.grauHintId == item.id}
        target={
          this.state.grauHintInerente
            ? 'colgrauRiscoInerente' + item.id
            : 'colgrauRisco' + item.id
        }
      >
        <PopoverBody style={{ width: 300 }}>
          <strong>
            {this.props.intl.formatMessage(
              { id: 'haAvaliacoesRiscoAtrasadas' },
              { risco: this.props.resources.risco }
            )}
          </strong>
        </PopoverBody>
      </Popover>
    );
  }

  renderStatus(item) {
    if (!item.status) return;

    return (
      <Badge
        pill
        className="text-white"
        style={item.status?.cor ? { background: item.status.cor } : {}}
      >
        {item.status?.descricao ? item.status.descricao : '-'}
      </Badge>
    );
  }

  renderLabelMenu() {
    return <MdiIcon icon="dots-vertical" />;
  }

  renderMenu(item) {
    return (
      <DropdownItemRisco
        showEdit={item.permissions && item.permissions.allowEdit}
        item={item}
        renderLabel={this.renderLabelMenu}
        {...this.props}
      />
    );
  }

  renderToleranciaRisco(item) {
    const { ratingToleranciaRisco } = item;
    if (!ratingToleranciaRisco) return;
    return (
      <div>
        <Badge
          pill
          className="text-white"
          style={{ background: ratingToleranciaRisco?.cor }}
        >
          {ratingToleranciaRisco?.descricao}
        </Badge>
      </div>
    );
  }

  renderGrauRisco(grauRisco, inerente, item) {
    if (!(grauRisco && grauRisco.cor)) return;
    const tooltipId = inerente
      ? 'colgrauRiscoInerente' + item.id
      : 'colgrauRisco' + item.id;
    return (
      <div
        onMouseEnter={this.handleMouseOver.bind(this, item, inerente)}
        onMouseLeave={() => this.handleMouseOut(inerente)}
        id={tooltipId}
      >
        {grauRisco && grauRisco.cor && (
          <div>
            <MdiIcon
              icon="circle"
              color={grauRisco.cor}
              colorHover={grauRisco.cor}
              size={22}
            ></MdiIcon>
            {item.avaliacaoPendente && (
              <strong style={{ color: grauRisco.cor }} color={grauRisco.cor}>
                !
              </strong>
            )}
          </div>
        )}
      </div>
    );
  }

  renderFarol(item) {
    return (
      <span>
        {item.farolRiscoAvaliacao && item.farolRiscoAvaliacao.cor && (
          <MdiIcon
            icon={item.farolRiscoAvaliacao.icone}
            color={item.farolRiscoAvaliacao.cor}
            colorHover={item.farolRiscoAvaliacao.cor}
            size={22}
          ></MdiIcon>
        )}
      </span>
    );
  }

  handleAssistenteVirtualClick() {
    const { user, intl, model, resources } = this.props;

    const promptCausas = intl.formatMessage(
      { id: 'promptSugestaoRiscos' },
      { riscos: resources.riscos, risco: resources.risco }
    );

    const msgConsultoraVirtualActioDesvio = intl.formatMessage(
      {
        id: 'tituloSophieSugestaoRiscos',
      },
      { riscos: resources.riscos }
    );

    ContentManager.addContent(
      <AssistenteVirtual
        user={user}
        prompt={promptCausas}
        title={msgConsultoraVirtualActioDesvio}
        showCause={false}
        actionsToolBar={this.renderButtonCreateRisk()}
        interactionType={TIPO_INTERACAO_SOPHIE.SUGERIR_RISCO}
      />
    );
  }

  showCreateRisk() {
    ContentManager.addContent(
      <RiscoEditView
        mode="edit"
        handleClose={this.refreshView}
        {...this.props}
      />
    );
  }

  renderButtonCreateRisk() {
    return [
      <Button
        id={'botaoIdentificarRisco'}
        key={1}
        className="ml-2"
        onClick={() => this.showCreateRisk()}
        type="primary"
      >
        <MdiIcon icon="plus" />
        <span className="ml-2">
          {capitalizeFirstLetter(this.props.resources.riscos)}
        </span>
      </Button>,
    ];
  }

  renderActionsToolBar() {
    const { intl, handleSelectRisk, resources } = this.props;
    return this.props.moduloInteligenciaArtificial
      ? [
          <div style={{ maxHeight: '35px' }}>
            {handleSelectRisk && (
              <Button
                className="pull-right ml-2"
                leftIcon="magnify"
                type="primary"
                onClick={handleSelectRisk}
              >
                {intl.formatMessage(
                  { id: 'labelVincularRisco' },
                  { risco: resources.risco }
                )}
              </Button>
            )}
            <Button
              id={'sophieIdenticarRiscos'}
              key={1}
              className="ml-2"
              style={{ height: '35px' }}
              onClick={this.handleAssistenteVirtualClick}
              type="primary"
            >
              <img alt="IA logo" src="/ia/ia_branco.svg" />
              <span className="ml-2">
                {this.props.intl.formatMessage(
                  { id: 'labelIdentificarRiscos' },
                  {
                    riscos: resources.riscos,
                  }
                )}
              </span>
            </Button>
            <UncontrolledTooltip
              placement="right"
              target={'sophieIdenticarRiscos'}
            >
              {this.props.intl.formatMessage(
                {
                  id: 'msgToolTipIdentificarRiscos',
                },
                { riscos: resources.riscos }
              )}
            </UncontrolledTooltip>
          </div>,
        ]
      : [
          <div style={{ maxHeight: '35px' }}>
            {handleSelectRisk && (
              <Button
                className="pull-right ml-2"
                leftIcon="magnify"
                type="primary"
                onClick={handleSelectRisk}
              >
                {intl.formatMessage(
                  { id: 'labelVincularRisco' },
                  { risco: resources.risco }
                )}
              </Button>
            )}
          </div>,
        ];
  }

  atualizarParametros(forceRefresh) {
    const {
      areaId,
      areaNome,
      areasSubordinadas,
      nome,
      statusId,
      statusDescricao,
      idIntegracao,
      processoId,
      modeloRiscoId,
      matrizRiscoId,
      tipoRiscoId,
      responsavelId,
      responsavelNome,
      grausRiscoInerenteId,
      grausRiscoInerenteNome,
      severidadeInerenteId,
      severidadeInerenteNome,
      probabilidadeInerenteId,
      probabilidadeInerenteNome,
      grausRiscoResidualId,
      grausRiscoResidualNome,
      severidadeResidualId,
      severidadeResidualNome,
      probabilidadeResidualId,
      probabilidadeResidualNome,
      desconsiderarStatusConcluido,
      desconsiderarStatusCancelado,
      grauRiscoId,
      causasRiscoId,
      causasRiscoNome,
      consequenciasRiscoId,
      consequenciasRiscoNome,
      tratamentoId,
      tratamentoNome,
      statusAvaliacaoId,
      statusAvaliacaoNome,
      farolPontoControleId,
      farolPontoControleNome,
      incluirSubprocessos,
      processoNome,
    } = this.state.queryString;

    if (
      forceRefresh ||
      (window.location.search && !window.location.pathname.includes('arvore'))
    ) {
      const parametroUrl = {
        areas: areaId
          ? Array.isArray(areaId)
            ? areaId.map((id, index) => {
                return { id, descricao: areaNome[index] };
              })
            : [{ id: areaId, descricao: areaNome ? areaNome : '' }]
          : null,
        areasSubordinadas: areasSubordinadas === 'true',
        nome,
        status: statusId ? { id: statusId, descricao: statusDescricao } : null,
        idIntegracao,
        processos: processoId
          ? Array.isArray(processoId)
            ? processoId.map((id, index) => {
                return {
                  id,
                  descricao: processoNome ? processoNome[index] : '',
                };
              })
            : [
                {
                  id: processoId,
                  descricao: processoNome ? processoNome : `Id (${processoId})`,
                },
              ]
          : null,
        incluirSubprocessos: incluirSubprocessos === 'true',
        modeloRisco: modeloRiscoId
          ? { id: modeloRiscoId, descricao: '' }
          : null,
        matrizRisco: matrizRiscoId
          ? { id: matrizRiscoId, descricao: '' }
          : null,
        grauRisco: grauRiscoId
          ? {
              id: grauRiscoId,
              descricao: grausRiscoResidualNome
                ? grausRiscoResidualNome[0]
                : '',
            }
          : null,
        tipoRisco: tipoRiscoId ? { id: tipoRiscoId, descricao: '' } : null,
        responsaveis: responsavelId
          ? [
              {
                id: responsavelId,
                descricao: responsavelNome,
                nome: responsavelNome,
              },
            ]
          : null,
        grausRiscoInerente: grausRiscoInerenteId
          ? grausRiscoInerenteId.map((id, index) => {
              return { id: id, descricao: grausRiscoInerenteNome[index] };
            })
          : null,
        severidadeInerente: severidadeInerenteId
          ? severidadeInerenteId.map((id, index) => {
              return { id: id, nome: severidadeInerenteNome[index] };
            })
          : null,
        probabilidadeInerente: probabilidadeInerenteId
          ? probabilidadeInerenteId.map((id, index) => {
              return { id: id, nome: probabilidadeInerenteNome[index] };
            })
          : null,
        grausRiscoResidual: grausRiscoResidualId
          ? grausRiscoResidualId.map((id, index) => {
              return { id: id, descricao: grausRiscoResidualNome[index] };
            })
          : null,
        severidadeResidual: severidadeResidualId
          ? severidadeResidualId.map((id, index) => {
              return { id: id, nome: severidadeResidualNome[index] };
            })
          : null,
        probabilidadeResidual: probabilidadeResidualId
          ? probabilidadeResidualId.map((id, index) => {
              return { id: id, nome: probabilidadeResidualNome[index] };
            })
          : null,
        desconsiderarStatusConcluido: desconsiderarStatusConcluido === 'true',
        desconsiderarStatusCancelado: desconsiderarStatusCancelado === 'true',
        causasRisco: causasRiscoId
          ? causasRiscoId.map((id, index) => {
              return { id: id, descricao: causasRiscoNome[index] };
            })
          : null,
        consequenciasRisco: consequenciasRiscoId
          ? consequenciasRiscoId.map((id, index) => {
              return { id: id, descricao: consequenciasRiscoNome[index] };
            })
          : null,
        tratamento: tratamentoId
          ? { id: tratamentoId, descricao: tratamentoNome }
          : null,
        statusAvaliacao: statusAvaliacaoId
          ? { id: statusAvaliacaoId, descricao: statusAvaliacaoNome }
          : null,
        farolPontoControle: farolPontoControleId
          ? { id: farolPontoControleId, descricao: farolPontoControleNome }
          : null,
      };
      this.setState({
        parametroUrl: parametroUrl,
        refreshFilterChip: ++this.state.refreshFilterChip,
      });
    }
  }

  encodeQueryData(data) {
    var ret = [];
    for (let d in data)
      if (data[d]) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    return ret.join('&');
  }

  removeFilterUrl(field) {
    const data = {
      ...this.state.queryString,
      ...Object.entries(this.state.camposCustomizadosUrl).reduce(
        (obj, [key, value]) => ({
          ...obj,
          [`campoCustomizado.${key}`]: value,
        }),
        {}
      ),
    };

    const hasFilterUrl =
      data && data.hasOwnProperty(field) && data[field] != null;

    if (field === 'status') {
      delete data.statusId;
      delete data.statusDescricao;
    } else if (field === 'responsaveis') {
      delete data.responsavelId;
      delete data.responsavelNome;
    } else if (field === 'areas') {
      delete data.areaId;
      delete data.areaNome;
    } else if (field === 'grausRiscoInerente') {
      delete data.grausRiscoInerenteId;
      delete data.grausRiscoInerenteNome;
    } else if (field === 'severidadeInerente') {
      delete data.severidadeInerenteId;
      delete data.severidadeInerenteNome;
    } else if (field === 'probabilidadeInerente') {
      delete data.probabilidadeInerenteId;
      delete data.probabilidadeInerenteNome;
    } else if (field === 'grausRiscoResidual') {
      delete data.grausRiscoResidualId;
      delete data.grausRiscoResidualNome;
    } else if (field === 'severidadeResidual') {
      delete data.severidadeResidualId;
      delete data.severidadeResidualNome;
    } else if (field === 'probabilidadeResidual') {
      delete data.probabilidadeResidualId;
      delete data.probabilidadeResidualNome;
    } else if (field === 'causasRisco') {
      delete data.causasRiscoId;
      delete data.causasRiscoNome;
    } else if (field === 'consequenciasRisco') {
      delete data.consequenciasRiscoId;
      delete data.consequenciasRiscoNome;
    } else if (field === 'tratamento') {
      delete data.tratamentoId;
      delete data.tratamentoNome;
    } else if (field === 'areasSubordinadas') {
      delete data.areasSubordinadas;
    } else if (field === 'statusAvaliacao') {
      delete data.statusAvaliacaoId;
      delete data.statusAvaliacaoNome;
    } else if (field === 'farolPontoControle') {
      delete data.farolPontoControleId;
      delete data.farolPontoControleNome;
    }

    if (hasFilterUrl) {
      history.replace({
        pathname: `/gerenciar/risco`,
        search: this.encodeQueryData(data),
      });
    }

    this.setState({ queryString: data }, () => this.atualizarParametros(true));
  }

  renderDescricaoGrauRisco(item, field = 'descricaoGrauRisco') {
    return item ? (
      <div className=" text-center">{item[field]}</div>
    ) : (
      <div></div>
    );
  }

  refreshView() {
    this.setState({ saved: ++this.state.saved });
    this.props.refreshList && this.props.refreshList(true);
  }

  renderComentarioSeveridade(item, inerente) {
    const idComentario = this.obterIdPopoverComentarioSeveridade(
      item,
      true,
      inerente
    );
    const comentario = inerente
      ? item?.comentarioSeveridadeInerente
      : item?.comentarioSeveridade;

    return (
      <div
        style={{ cursor: comentario ? 'pointer' : 'default' }}
        onMouseEnter={() =>
          this.handleMouseOverComentario(item, true, inerente)
        }
        onClick={() =>
          comentario && this.handleMouseClickComentario(item, true, inerente)
        }
        onMouseLeave={() => comentario && this.handleMouseOutPopOver()}
        id={idComentario}
      >
        <span
          style={{
            fontWeight:
              this.state.comentarioClickHintId == idComentario
                ? 'bold'
                : 'normal',
          }}
        >
          {inerente ? item.severidadeInerente : item.severidade}{' '}
        </span>
      </div>
    );
  }

  obterIdPopoverComentarioSeveridade(item, severidade, inerente) {
    const idComentario = `col${severidade ? 'severidade' : 'probabilidade'}${
      inerente ? 'Inerente' : ''
    }${item.id}`;
    return idComentario;
  }

  popoverComentarioSeveridade(item, inerente) {
    const idComentario = this.obterIdPopoverComentarioSeveridade(
      item,
      true,
      inerente
    );
    const comentario = inerente
      ? item?.comentarioSeveridadeInerente
      : item?.comentarioSeveridade;

    if (!comentario) return;

    return (
      <Popover
        placement="auto"
        isOpen={this.state.comentarioHintId == idComentario}
        target={idComentario}
      >
        <PopoverBody className={classes.popoverBody}>
          <span>{comentario}</span>
        </PopoverBody>
      </Popover>
    );
  }

  popoverComentarioProbabilidade(item, inerente) {
    const idComentario = this.obterIdPopoverComentarioSeveridade(
      item,
      false,
      inerente
    );
    const comentario = inerente
      ? item?.comentarioProbabilidadeInerente
      : item?.comentarioProbabilidade;

    if (!comentario) return;

    return (
      <Popover
        placement="auto"
        isOpen={this.state.comentarioHintId == idComentario}
        target={idComentario}
      >
        <PopoverBody className={classes.popoverBody}>
          <span>{comentario}</span>
        </PopoverBody>
      </Popover>
    );
  }

  renderRemoveAssociation(item, index) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <IconButton
          icon="close-circle"
          className="clarear cursor-pointer"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            this.props.handleRemoveAssociation &&
            this.props.handleRemoveAssociation(item, index)
          }
        />
      </div>
    );
  }

  renderTags(item) {
    return item.tags?.map((x) => (
      <Badge pill className="text-white" style={{ background: x.cor }}>
        {x.descricao}
      </Badge>
    ));
  }

  renderComentarioProbabilidade(item, inerente) {
    const idComentario = this.obterIdPopoverComentarioSeveridade(
      item,
      false,
      inerente
    );
    const comentario = inerente
      ? item?.comentarioProbabilidadeInerente
      : item?.comentarioProbabilidade;

    return (
      <div
        style={{ cursor: comentario ? 'pointer' : 'default' }}
        onClick={() =>
          comentario && this.handleMouseClickComentario(item, false, inerente)
        }
        onMouseEnter={() =>
          this.handleMouseOverComentario(item, false, inerente)
        }
        onMouseLeave={() => comentario && this.handleMouseOutPopOver()}
        id={idComentario}
      >
        <span
          style={{
            fontWeight:
              this.state.comentarioClickHintId == idComentario
                ? 'bold'
                : 'normal',
          }}
        >
          {inerente ? item.probabilidadeInerente : item.probabilidade}{' '}
        </span>
      </div>
    );
  }

  showColumn(column) {
    const { showColumns } = this.props;
    if (!showColumns || showColumns.length === 0) return true;

    return showColumns.includes(column);
  }

  handleCustomColumnsOrder(allColumns, columns) {
    const { showColumns } = this.props;
    const customColumns = allColumns.filter(
      (column) =>
        column?.visible &&
        showColumns.includes(
          column?.valueField
        ) /* || (column?.customFields && showColumns.includes(column?.valueField))*/
    );

    if (showColumns && showColumns.length > 0) {
      const newOrder = customColumns.sort((a, b) => {
        return (
          showColumns.indexOf(a?.valueField) -
          showColumns.indexOf(b?.valueField)
        );
      });

      return newOrder;
    }

    return columns;
  }

  createColumns() {
    const {
      intl,
      resources,
      usoMatrizRisco,
      modules,
      handleRemoveAssociation,
    } = this.props;
    const hasGR = modules.some((m) => m.id === MODULOS.GESTAO_RISCO && m.ativo);
    const { configuracao } = this.state;

    this.setState({
      columns: [
        <ListColumn
          headerText={intl.formatMessage({ id: 'nome' })}
          required
          minWidth={250}
          visible={this.showColumn('nome')}
          valueField="nome"
          sortField={
            usoMatrizRisco ? 'Risco.UltimaVersao.Nome' : 'UltimaVersao.Nome'
          }
          valueFunction={this.renderNome}
          default
        />,
        <ListColumn
          headerText={intl.formatMessage({ id: 'codigo' })}
          tooltip="Código de Importação"
          visible={this.showColumn('idIntegracao')}
          valueField="idIntegracao"
          sortField={
            usoMatrizRisco
              ? 'Risco.UltimaVersao.IdIntegracao'
              : 'UltimaVersao.idIntegracao'
          }
          width={100}
          default
        />,
        <ListColumn
          headerText={capitalizeFirstLetter(resources.area)}
          valueField="area"
          visible={this.showColumn('area')}
          default
          sortField="UltimaVersao.Area.Nome"
        />,
        <ListColumn
          headerText={capitalizeFirstLetter(resources.responsaveis)}
          visible={this.showColumn('responsaveis')}
          valueField="responsaveis"
          default
        />,
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'label.severidade' },
            { severidade: resources.severidade }
          )}
          valueField="severidade"
          visible={this.showColumn('severidade')}
          sortField="UltimaVersao.SeveridadeResidual.Nome"
          valueFunction={(item) => this.renderComentarioSeveridade(item, false)}
          popoverFunction={(item) =>
            this.popoverComentarioSeveridade(item, false)
          }
          default
        />,
        <ListColumn
          headerText={resources.probabilidade}
          valueField="probabilidade"
          visible={this.showColumn('probabilidade')}
          valueFunction={(item) =>
            this.renderComentarioProbabilidade(item, false)
          }
          popoverFunction={(item) =>
            this.popoverComentarioProbabilidade(item, false)
          }
          sortField="UltimaVersao.ProbabilidadeResidual.Nome"
          default
        />,
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'labelGrauRiscoResidual' },
            { risco: resources.risco }
          )}
          valueField="grauRisco"
          visible={this.showColumn('grauRisco')}
          valueFunction={(item) =>
            this.renderGrauRisco(item && item.grauRisco, false, item)
          }
          popoverFunction={this.renderPopoverHintGrauRisco}
          default
        />,
        <ListColumn
          headerText={`${intl.formatMessage({
            id: 'descricao',
          })} ${intl.formatMessage(
            { id: 'labelGrauRiscoResidual' },
            { risco: resources.risco }
          )}`}
          visible={this.showColumn('descricaoGrauRisco')}
          valueField="descricaoGrauRisco"
          valueFunction={(item) =>
            this.renderDescricaoGrauRisco(item, 'descricaoGrauRisco')
          }
          default
        />,
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'label.periodicidadeAvaliacao' },
            { avaliacao: resources.avaliacao }
          )}
          visible={this.showColumn('periodicidadeAvaliacao')}
          valueField="periodicidadeAvaliacao"
          default
        />,
        <ListColumn
          headerText={intl.formatMessage({ id: 'status' })}
          visible={this.showColumn('status')}
          valueField="status"
          sortField="UltimaVersao.status"
          valueFunction={this.renderStatus}
          default
        />,
        <ListColumn
          headerText={capitalizeFirstLetter(resources.pontodecontrole)}
          visible={hasGR && this.showColumn('descricoesPontoControle')}
          valueField="descricoesPontoControle"
          minWidth={300}
        />,
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage(
              { id: 'farolAvaliacao' },
              { avaliacao: resources.avaliacao }
            )
          )}
          visible={this.showColumn('farolRiscoAvaliacao')}
          valueField="farolRiscoAvaliacao"
          valueFunction={this.renderFarol}
        />,
        <ListColumn
          headerText={capitalizeFirstLetter(resources.processos)}
          visible={hasGR && this.showColumn('descricoesProcessos')}
          valueField="descricoesProcessos"
          default
        />,
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage(
              { id: 'labelCausasDoRisco' },
              {
                pontosproblematicos: resources.pontosproblematicos,
                risco: resources.risco,
              }
            )
          )}
          visible={this.showColumn('descricoesCausas')}
          valueField="descricoesCausas"
          minWidth={300}
        />,
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage(
              { id: 'labelConsequenciasDoRisco' },
              {
                consequencias: resources.consequencias,
                risco: resources.risco,
              }
            )
          )}
          visible={this.showColumn('descricoesConsequencias')}
          valueField="descricoesConsequencias"
          minWidth={300}
        />,

        configuracao?.habilitarCalculoDeScores && (
          <ListColumn
            headerText={intl.formatMessage({ id: 'pontuacao' })}
            valueFunction={({ score, habilitadoCalculoDeScores }) =>
              habilitadoCalculoDeScores ? score : ''
            }
            visible={this.showColumn('score')}
            valueField="score"
            sortField="UltimaVersao.Score"
            default
          />
        ),
        configuracao?.avaliarRiscoInerente && (
          <ListColumn
            headerText={intl.formatMessage(
              { id: 'labelGrauRiscoInerente' },
              { risco: resources.risco }
            )}
            visible={this.showColumn('grauRiscoInerente')}
            valueField="grauRiscoInerente"
            valueFunction={(item) =>
              this.renderGrauRisco(item && item.grauRiscoInerente, true, item)
            }
            popoverFunction={this.renderPopoverHintGrauRiscoInerente}
            default
          />
        ),
        configuracao?.avaliarRiscoInerente && (
          <ListColumn
            headerText={`${intl.formatMessage({
              id: 'descricao',
            })} ${intl.formatMessage(
              { id: 'labelGrauRiscoInerente' },
              { risco: resources.risco }
            )}`}
            visible={this.showColumn('descricaoGrauRiscoInerente')}
            valueField="descricaoGrauRiscoInerente"
            valueFunction={(item) =>
              this.renderDescricaoGrauRisco(item, 'descricaoGrauRiscoInerente')
            }
            default
          />
        ),
        configuracao?.habilitarCalculoDeScores &&
          configuracao?.avaliarRiscoInerente && (
            <ListColumn
              headerText={intl.formatMessage({
                id: 'labelScoreInerente',
              })}
              valueFunction={({ scoreInerente, habilitadoCalculoDeScores }) =>
                habilitadoCalculoDeScores ? scoreInerente : ''
              }
              visible={this.showColumn('scoreInerente')}
              valueField="scoreInerente"
              default
            />
          ),
        configuracao?.avaliarRiscoInerente && (
          <ListColumn
            headerText={intl.formatMessage(
              { id: 'labelSeveridadeInerente' },
              { severidade: resources.severidade }
            )}
            visible={this.showColumn('severidadeInerente')}
            valueField="severidadeInerente"
            valueFunction={(item) =>
              this.renderComentarioSeveridade(item, true)
            }
            popoverFunction={(item) =>
              this.popoverComentarioSeveridade(item, true)
            }
            default
          />
        ),
        configuracao?.avaliarRiscoInerente && (
          <ListColumn
            headerText={intl.formatMessage(
              { id: 'labelProbabilidadeInerente' },
              { probabilidade: resources.probabilidade }
            )}
            visible={this.showColumn('probabilidadeInerente')}
            valueField="probabilidadeInerente"
            valueFunction={(item) =>
              this.renderComentarioProbabilidade(item, true)
            }
            popoverFunction={(item) =>
              this.popoverComentarioProbabilidade(item, true)
            }
            default
          />
        ),
        configuracao?.habilitarAcompanhamentoToleranciaRisco && (
          <ListColumn
            headerText={intl.formatMessage(
              { id: 'labelToleranciaRisco' },
              { risco: resources.risco }
            )}
            visible={this.showColumn('toleranciaRisco')}
            valueField="toleranciaRisco"
            default
          />
        ),
        configuracao?.habilitarAcompanhamentoToleranciaRisco && (
          <ListColumn
            headerText={intl.formatMessage(
              { id: 'labelRatingRisco' },
              { risco: resources.risco }
            )}
            visible={this.showColumn('ratingToleranciaRisco')}
            valueField="ratingToleranciaRisco"
            valueFunction={(item) => this.renderToleranciaRisco(item)}
            default
          />
        ),
        <ListColumn
          headerText={intl.formatMessage({
            id: 'impactoFinanceiroResidualEstimado',
          })}
          //sortField="impactoFinanceiroResidualEstimado"
          valueFunction={(item) =>
            item.impactoFinanceiroResidualEstimado ? (
              <div>
                {configuracao?.unidadeMedidaImpactoFinanceiro?.sigla}{' '}
                {formatNumber(item.impactoFinanceiroResidualEstimado, 2)}
              </div>
            ) : (
              <>-</>
            )
          }
          visible={
            configuracao?.acompanharImpactoFinanceiroEventoRisco &&
            this.showColumn('impactoFinanceiroResidualEstimado')
          }
          valueField="impactoFinanceiroResidualEstimado"
        />,
        <ListColumn
          headerText={intl.formatMessage({
            id: 'impactoFinanceiroInerenteEstimado',
          })}
          //sortField="impactoFinanceiroInerenteEstimado"
          valueFunction={(item) =>
            item.impactoFinanceiroInerenteEstimado ? (
              <div>
                {configuracao?.unidadeMedidaImpactoFinanceiro?.sigla}{' '}
                {formatNumber(item.impactoFinanceiroInerenteEstimado, 2)}
              </div>
            ) : (
              <>-</>
            )
          }
          visible={
            configuracao?.avaliarRiscoInerente &&
            configuracao?.acompanharImpactoFinanceiroEventoRisco &&
            this.showColumn('impactoFinanceiroInerenteEstimado')
          }
          valueField="impactoFinanceiroInerenteEstimado"
        />,
        <ListColumn
          headerText={`
                  ${intl.formatMessage({
                    id: 'reducaoImpactoFinanceiroRealizado',
                  })} ${
            configuracao?.unidadeMedidaImpactoFinanceiro?.sigla
              ? `(${configuracao?.unidadeMedidaImpactoFinanceiro?.sigla})`
              : ''
          }`}
          sortField="reducaoImpactoFinanceiroRealizado"
          valueFunction={(item) =>
            item.reducaoImpactoFinanceiroRealizado ? (
              <div>
                {configuracao?.unidadeMedidaImpactoFinanceiro?.sigla}{' '}
                {formatNumber(item.reducaoImpactoFinanceiroRealizado, 2)}
              </div>
            ) : (
              <>-</>
            )
          }
          visible={
            configuracao?.avaliarRiscoInerente &&
            configuracao?.acompanharImpactoFinanceiroEventoRisco &&
            this.showColumn('reducaoImpactoFinanceiroRealizado')
          }
          valueField="reducaoImpactoFinanceiroRealizado"
        />,
        <ListColumn
          headerText={intl.formatMessage({
            id: 'reducaoImpactoFinanceiroPercentual',
          })}
          sortField="reducaoImpactoFinanceiroPercentual"
          valueFunction={(item) =>
            item.reducaoImpactoFinanceiroPercentual ? (
              <div>
                {formatNumber(item.reducaoImpactoFinanceiroPercentual, 2)} %
              </div>
            ) : (
              <>-</>
            )
          }
          visible={
            configuracao?.avaliarRiscoInerente &&
            configuracao?.acompanharImpactoFinanceiroEventoRisco &&
            this.showColumn('reducaoImpactoFinanceiroPercentual')
          }
          valueField="reducaoImpactoFinanceiroPercentual"
        />,
        <ListColumn
          headerText={capitalizeFirstLetter(resources.tipoRisco)}
          valueField="tipoRisco"
          visible={this.showColumn('tipoRisco')}
          default
        />,
        <ListColumn
          headerText={capitalizeFirstLetter(
            intl.formatMessage({ id: 'tratamento' })
          )}
          valueField="tratamentoRisco"
          visible={this.showColumn('tratamentoRisco')}
          default
        />,
        <ListColumn
          headerText={intl.formatMessage(
            { id: 'tags' },
            { tags: resources.tags }
          )}
          valueField="tags"
          valueFunction={this.renderTags}
          visible={configuracao?.podeVisualizarTag && this.showColumn('tags')}
          default
        />,
        handleRemoveAssociation && (
          <ListColumn
            headerText={' '}
            required
            minWidth={80}
            visible={true}
            valueField="removeAssociation"
            valueFunction={(item) => this.renderRemoveAssociation(item)}
            default
          />
        ),
      ],
      isLoadingConfigs: false,
    });
  }

  getMergeColumns() {
    const { showColumns, columns, severidadesColumns, configuracao } =
      this.state;
    let newColumns = [];
    if (!severidadesColumns || configuracao?.avaliarSeveridadePorTipo) {
      newColumns = columns.filter((column) => column);

      if (showColumns && showColumns.length > 0) {
        newColumns = columns.sort((a, b) => {
          return (
            showColumns.indexOf(a.props?.valueField) -
            showColumns.indexOf(b.props?.valueField)
          );
        });
      }
      return newColumns;
    }

    newColumns = columns.concat(severidadesColumns);

    //order columns conforme showColumns
    if (showColumns && showColumns.length > 0) {
      newColumns = newColumns.sort((a, b) => {
        return (
          showColumns.indexOf(a.valueField) - showColumns.indexOf(b.valueField)
        );
      });
    }

    return newColumns;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.refreshColumns !== this.props.refreshColumns ||
      (newProps.showColumns && !this.state.showColumns)
    ) {
      this.setState({ showColumns: newProps.showColumns });
    }
  }

  render() {
    const {
      resources,
      params,
      showModal,
      showCustomFields,
      filtroDefault,
      dataSource,
      hideFiltroChip = false,
      ignoreFiltroId = false,
      disabled = false,
      isWidget = false,
      refreshColumns,
    } = this.props;
    const filterUrl = this.state.parametroUrl ? true : false;
    const {
      configuracao,
      columns,
      isLoadingConfigs,
      isLoadingTiposSeveridades,
      columnsDinamic,
    } = this.state;

    return (
      <LoadingContainer
        isLoading={isLoadingConfigs || isLoadingTiposSeveridades}
      >
        {!(isLoadingConfigs && isLoadingTiposSeveridades) &&
          configuracao &&
          resources.area &&
          columns && (
            <List
              url="/Risco"
              showCustomFields={showCustomFields}
              columnsDinamic={columnsDinamic}
              tipoItem={6}
              //urlEdit="/gerenciar/risco/edit"
              sort="UltimaVersao.Nome"
              searchComponent={
                showModal ? (
                  <SearchModal configuracao={configuracao} />
                ) : (
                  <Search configuracao={configuracao} />
                )
              }
              renderMenuAddOptions={true}
              renderMenu={this.renderMenu}
              showDelete={false}
              searchSchema={showModal ? SearchSchemaModal : SearchSchema}
              pageSize={20}
              valueField="nome"
              hideFiltroChip={hideFiltroChip}
              changedColumns={
                refreshColumns ? refreshColumns : this.state.changedColumns
              }
              filtroId={
                ignoreFiltroId
                  ? 0
                  : showModal
                  ? this.props.filtroId
                    ? this.props.filtroId
                    : 302
                  : 301
              }
              showSearch={this.props.showSearch ? this.props.showSearch : true}
              tag={
                this.props.ignoreTag
                  ? null
                  : showModal
                  ? 2011
                  : isWidget
                  ? 12031
                  : 2010
              }
              handleCustomColumnsOrder={
                isWidget ? this.handleCustomColumnsOrder : null
              }
              selectedFilter={filtroDefault && { valor: filtroDefault }}
              parameters={params}
              saved={this.props.saved ? this.props.saved : this.state.saved}
              editComponent={RiscoEditView}
              closeModal={this.state.closeModal}
              handleClose={this.closeModal}
              showNew={!disabled}
              modelValue={
                filtroDefault ? filtroDefault : this.state.parametroUrl
              }
              dataSource={dataSource}
              refreshFilterChip={
                this.props.refreshFilterChip + this.state.refreshFilterChip
              }
              onRemoveFilterChip={filterUrl && this.removeFilterUrl}
              openLastFilter={filterUrl ? false : true}
              actionsToolBarEnd={this.renderActionsToolBar()}
              camposCustomizadosUrl={this.state.camposCustomizadosUrl}
              showEdit={false}
              {...this.props}
            >
              {this.getMergeColumns()}
            </List>
          )}
        {!showModal && <Legenda />}
      </LoadingContainer>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.user.termos,
    modules: state.user.licenca.modulos,
    user: state.user,
    moduloInteligenciaArtificial: state.user.moduloInteligenciaArtificial,
  };
}
export default injectIntl(connect(mapStateToProps)(Risco));
