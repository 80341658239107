import { useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Row, Col } from 'reactstrap';

import DataInicioTermino from 'src/paginas/formula/componentes/inicio-termino';
import { InputGroup } from 'src/componentes/Form';
import { EditDialog } from 'src/componentes/CRUD';
import ContentManager from 'src/componentes/content-manager';
import Tabs from 'src/componentes/tabs/tabs';
import Tab from 'src/componentes/tabs/tab';
import GuiaDatasetId from '../RiscoAvaliacaoPowerBIGuia';

import Log from './Log';

function FormEdit({
  values,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  errors,
}) {
  const intl = useIntl();
  const [tabIndex, setTabIndex] = useState(0);
  const isNew = !values?.id;

  const openModal = () => {
    ContentManager.addContent(<GuiaDatasetId />);
  };

  const renderForm = () => {
    const item = values?.item;
    const nomeItem = item?.nome;
    const periodicidade = values?.periodicidade?.descricao;
    
    const info = intl
      .formatMessage({ id: 'infoConfiguracaoValoresPowerBI' })
      .replace(/{nomeItem}/gi, function (matched) {
        switch (matched) {
          case '{nomeItem}':
            return `(${nomeItem})`;
        }
      });

    return (
      <div>
        <Row>
          <Col md={12}>
            <div className="mb-2">
              <span>{info}</span>
            </div>
          </Col>
        </Row>

        <hr className="my-2"></hr>

        <Row className="mt-3 mb-4">
          <Col md={12}>
            <span className="font-weight-bold">
              {intl.formatMessage({ id: 'periodicidade' })}:{' '}
            </span>
            <span className="font-weight-bolder">{periodicidade}</span>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <InputGroup
              formGroupStyle={{ marginBottom: 0 }}
              label={intl.formatMessage({ id: 'datasetId' })}
              required
              id="datasetId"
              name="datasetId"
              type="text"
              value={values?.datasetId}
              error={touched.datasetId && errors.datasetId}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <a
              style={{
                fontSize: '0.75rem',
                width: 'fit-content',
                textDecoration: 'underline',
              }}
              className="mt-n2 text-primary font-weight-bolder"
              onClick={openModal}
            >
              {intl.formatMessage({ id: 'comoObter' })}
            </a>
          </Col>

          <Col md={4}>
            <InputGroup
              label={intl.formatMessage({ id: 'nomeTabelaCalculo' })}
              required
              id="nomeTabelaCalculo"
              name="nomeTabelaCalculo"
              type="text"
              value={values?.nomeTabelaCalculo}
              error={touched.nomeTabelaCalculo && errors.nomeTabelaCalculo}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Col>

          <Col md={4}>
            <InputGroup
              label={intl.formatMessage({ id: 'nomeColunaCodigoItem' })}
              required
              id="nomeColunaCodigoItem"
              name="nomeColunaCodigoItem"
              type="text"
              value={values?.nomeColunaCodigoItem}
              error={
                touched.nomeColunaCodigoItem && errors.nomeColunaCodigoItem
              }
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={4}>
            <InputGroup
              label={intl.formatMessage({ id: 'codigoItemPowerBI' })}
              required
              id="codigoItemPowerBI"
              name="codigoItemPowerBI"
              type="text"
              value={values?.codigoItemPowerBI}
              error={touched.codigoItemPowerBI && errors.codigoItemPowerBI}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Col>

          <Col md={4}>
            <InputGroup
              label={intl.formatMessage({ id: 'nomeColunaValorItem' })}
              required
              id="nomeColunaValorItem"
              name="nomeColunaValorItem"
              type="text"
              value={values?.nomeColunaValorItem}
              error={touched.nomeColunaValorItem && errors.nomeColunaValorItem}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Col>

          <Col md={4}>
            <InputGroup
              label={intl.formatMessage({ id: 'nomeColunaDataItem' })}
              required
              id="nomeColunaDataItem"
              name="nomeColunaDataItem"
              type="text"
              value={values?.nomeColunaDataItem}
              error={touched.nomeColunaDataItem && errors.nomeColunaDataItem}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={8}>
            <DataInicioTermino
              required
              dataInicio={{
                label: intl.formatMessage({ id: 'dataInicio' }),
                value: values.dataInicio,
                requestChange: (value) => setFieldValue('dataInicio', value),
                errors: errors.dataInicio,
              }}
              dataTermino={{
                label: intl.formatMessage({ id: 'dataTermino' }),
                value: values.dataTermino,
                requestChange: (value) => setFieldValue('dataTermino', value),
              }}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Tabs currenttabIndex={tabIndex}>
      <Tab label={intl.formatMessage({ id: 'label.geral' })} key={0}>
        {values && renderForm()}
      </Tab>
      {!isNew && (
        <Tab label={intl.formatMessage({ id: 'log' })} key={1}>
          <Log id={values?.id} />
        </Tab>
      )}
    </Tabs>
  );
}

function ItemValorPowerBIEditView({ ...rest }) {
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    dataInicio: Yup.string().max(1000),
    datasetId: Yup.string()
      .max(300)
      .nullable()
      .required(intl.formatMessage({ id: 'campoObrigatorio' })),
    codigoItemPowerBI: Yup.string()
      .max(300)
      .nullable()
      .required(intl.formatMessage({ id: 'campoObrigatorio' })),
    nomeTabelaCalculo: Yup.string()
      .max(300)
      .nullable()
      .required(intl.formatMessage({ id: 'campoObrigatorio' })),
    nomeColunaDataItem: Yup.string()
      .max(300)
      .nullable()
      .required(intl.formatMessage({ id: 'campoObrigatorio' })),

    nomeColunaCodigoItem: Yup.string()
      .max(300)
      .nullable()
      .required(intl.formatMessage({ id: 'campoObrigatorio' })),
    nomeColunaValorItem: Yup.string()
      .max(300)
      .nullable()
      .required(intl.formatMessage({ id: 'campoObrigatorio' })),
  });

  return (
    <EditDialog
      url="/RiscoAvaliacaoPowerBI"
      validationSchema={validationSchema}
      formComponent={FormEdit}
      title={intl.formatMessage({ id: 'configuracaoValoresPowerBI' })}
      padContent
      width="70%"
      maxHeight="90%"
      {...rest}
    />
  );
}

export default ItemValorPowerBIEditView;
